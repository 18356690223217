.push--services {
    margin-top: 280px;
    @include tablet() {
        margin-top: 80px;
    }

    &--header {
        text-align: center;
        @include tablet() {
            padding: 0 15px;
        }

        &--1 {
            @include yanone70(700, $color1);
            @include tablet() {
                @include yanone55(700, $color1);
            }
        }
    }

    &--items {
        display: flex;
        justify-content: space-between;
        margin-top: 40px;
        @include mq(null, $w1280) {
            overflow: auto;
        }
        @include tablet() {
            padding: 0 15px;
        }
    }

    &--item {
        flex: 0 0 230px;
        padding: 25px 40px;
        background-color: $color1;
        border-radius: 10px;
        transition: all 0.3s;
        margin: 20px 6px 0 6px;
        @include mq($w1280, $w1470) {
            flex: 0 1 230px;
        }

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }

        &:hover {
            transform: translateY(-20px);
        }
    }

    &--link {
        text-decoration: none;
    }

    &--picto {
        display: flex;
        justify-content: center;

        svg {
            width: 83px;
        }
    }

    &--txt {
        text-align: center;

        &--1 {
            @include yanone30(700, $white);
            margin-top: 10px;
        }

        &--2 {
            @include yanone18(400, $white);
            text-transform: uppercase;
            margin-top: 35px;
            position: relative;

            &:before {
                content: '';
                width: 56px;
                height: 2px;
                background-color: $color2;
                border-radius: 2px;
                position: absolute;
                top: -15px;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
}