.page--category {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
}

.category {
    &--heading {
        position: relative;

        @include tablet-up() {
            margin-bottom: 50px;
        }

        &--image {
            width: $w1470;
            max-width: 100%;
            height: 600px;
            margin: 0 auto 50px auto;
            background-color: $white;

            @include tablet() {
                height: auto;
                margin-bottom: 0;
            }

            img {
                @include object(contain, 0% 50%);
            }
        }

        &--content {
            background-color: $white;
            padding: 30px 50px;
            @include tablet() {
                padding: 20px;
            }

            @include tablet-up() {
                .has_image & {
                    position: absolute;
                    bottom: -45px;
                    left: 50px;
                    right: 50px;
                    box-shadow: 0 -20px 20px rgba($black, 0.16);
                    width: 820px;
                    max-width: calc(100% - 100px);
                }
            }
        }

        &--picto {
            display: flex;
            justify-content: center;
            margin-bottom: 15px;

            img {
                height: 45px;
            }
        }

        &--title {
            h1,
            .h1 {
                @include yanone70(700, $color1);
                text-align: center;
                margin: 0;
            }
        }

        &--description {
            text-align: center;

            .page--category--home & {
                margin-top: 20px;
            }

            h2,
            .h2 {
                @include cabin17(700, $color1);
                margin-top: 35px;
                margin-bottom: 20px;
            }

            h3,
            .h3 {
                @include cabin15(700, $color1);
                margin-top: 35px;
                margin-bottom: 20px;
            }

            p,
            ul,
            div {
                @include cabin15(400, $color1);
                margin: 10px 0;

                strong,
                b {
                    @include cabin15(700, $color1);
                    letter-spacing: -0.7px;

                    a {
                        color: $color2;
                        letter-spacing: 0;
                    }
                }

                a {
                    @include cabin15(700, $color2);

                    strong,
                    b {
                        color: $color2;
                        letter-spacing: 0;
                    }
                }
            }
        }

        &--cta {
            margin-top: 30px;
        }
    }

    &--footer {
        @include tablet() {
            padding: 0 15px;
        }

        .container {
            width: $w1260;
        }

        &--description {
            margin-top: 80px;

            h1,
            .h1 {
                @include yanone70(700, $color1);
                text-align: center;
                width: $w1050;
                margin: 0 auto 55px;
                padding: 0 30px;

                span {
                    color: $color2
                }
            }

            h2,
            h3,
            h4,
            h5,
            h6,
            .h2,
            .h3,
            .h4,
            .h5,
            .h6 {
                a {
                    color: $color2;
                }
            }

            h2,
            .h2 {
                @include yanone20(700, $color1);
                text-transform: uppercase;
            }

            h3,
            .h3 {
                @include yanone20(400, $color1);
            }

            h4,
            h5,
            h6,
            .h4,
            .h5,
            .h6 {
                @include yanone18(400, $color1);
            }

            p,
            ul,
            div {
                @include cabin15(400, $color1);
                margin: 20px 0;

                span {
                    @include cabin15(400, $color1);
                }

                b,
                strong {
                    @include cabin15(700, $color1);
                }

                a,
                a > * {
                    color: $color2;
                }
            }
            &.elementor {
                p,
                ul,
                div {
                    margin: 0;
                }
            }

            .span-title {
                text-transform: uppercase;
                font-weight: 700;
            }

            .bullet-points {
                padding: 0;
                list-style: none;

                li {
                    padding: 10px 0;

                    span {
                        @include yanone20(700, $color1);
                        text-transform: uppercase;
                    }
                }
            }
        }

        &--faq{
            &--items {
                border-top: 1px solid $grey;
            }

            &--item {
                &--header {
                    border-bottom: 1px solid $grey;
                    padding: 0 10px 10px 10px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    transition: all 0.3s;

                    &--txt {
                        @include yanone20(700, $color1);
                        text-transform: uppercase;
                        margin: 0;
                        transition: all 0.3s;
                        flex-grow: 1;
                    }

                    &:hover {
                        .category--footer--faq--item--header--txt {
                            color: $color2;
                        }

                        .category--footer--faq--item--picto {
                            .cls-1 {
                                stroke: $color2;
                            }
                        }
                    }
                }

                &--picto {
                    .picto-more-less {
                        .cls-1 {
                            fill: none;
                            stroke: $color1;
                            stroke-linecap: round;
                            stroke-width: 2px;
                            transition: all 0.3s;
                        }
                    }
                }

                &--content {
                    padding: 15px 70px 30px 40px;
                    border-bottom: 1px solid $grey;
                    display: none;
                    transition: all 0.3s;
                    @include mobile() {
                        padding: 0;
                    }
                }

                &--active {
                    .category--footer--faq--item--header {
                        border-color: $color2;

                        &--txt {
                            color: $color2;
                        }
                    }

                    .category--footer--faq--item--content {
                        display: block;
                    }

                    .category--footer--faq--item--picto {
                        .cls-1 {
                            stroke: $color2;
                        }

                        .line-more {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    &--search {
        &--heading {
            &--title {
                @include yanone40(400, $color1);
                text-transform: uppercase;
            }

            &--txt {
                @include yanone25(700, $color2);

                a {
                    color: $color1;
                }
            }
        }
    }
}

.categories {
    &--items {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    &--item {
        width: calc(50% - 25px);
        margin-top: 35px;
        padding: 10px;
        border: 1px solid $white;
        @include mobile() {
            width: 100%;
        }

        &:hover {
            border-color: $color2;
        }

        &--link {
            text-decoration: none;
        }

        &--image-picto {
            position: relative;
        }

        &--image {
            width: 100%;
            height: 385px;
            overflow: hidden;
            @include tablet() {
                height: 145px;
            }

            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                z-index: 2;
                width: 100%;
                height: 100%;
                background-color: $white;
                opacity: 0;
                transition: all 0.3s;
            }

            .categories--item:hover & {
                &:after {
                    opacity: 0.2;
                }
            }

            img {
                @include object(contain, 0% 50%);
                transition: all 0.3s;

                .categories--item:hover & {
                    transform: scale(1.1);
                }
            }
        }

        &--picto {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 5;
            width: 140px;
            height: 85px;
            background-color: $white;
            border-top-right-radius: 15px;
            padding: 20px;

            img {
                @include object(contain);
            }
        }

        &--name {
            @include yanone45(400, $color1);
            text-transform: uppercase;
            margin-top: 5px;
        }

        &--cta {
            @include yanone20(700, $color2);
            text-transform: uppercase;
            margin-top: 10px;
        }
    }
}