.push--reinsurance {
    margin-top: 75px;
    @include tablet() {
        padding: 15px;
    }

    &--items {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 15px 0;
        border-top: 1px solid $grey;
        border-bottom: 1px solid $grey;
    }

    &--item {
        width: 25%;
        padding: 60px;
        border-right: 1px solid $grey;
        @include small-desktop() {
            width: 50%;
            border-top: 1px solid $grey;

            &:nth-child(1),
            &:nth-child(2) {
                border-top: none;
            }

            &:nth-child(2n) {
                border-right: none;
            }
        }
        @include mobile() {
            padding: 15px;
        }

        &:nth-child(4n) {
            border-right: none;
        }
    }

    &--link {
        text-decoration: none;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &--picto {
        svg {
            width: 71px;
        }
    }

    &--txt {
        text-align: center;

        &--1 {
            @include yanone20(700, $color1);
            text-transform: uppercase;
            margin-top: 20px;
        }

        &--2 {
            @include cabin15(400, $color1);
            margin-top: 20px;
        }
    }
}