.featured-products,
.product--accessories {
    margin-top: 40px;

    &--inner {
        position: relative;
        @include tablet() {
            padding: 0 15px;
        }
    }

    &--txt {
        text-align: center;

        &--1 {
            @include yanone70(700, $color1);
            margin: 0;
            @include tablet() {
                @include yanone55(700, $color1);
            }
        }

        &--2 {
            @include cabin15(400, $color1);
            margin-top: 30px;
        }
    }

    &--products {
        white-space: nowrap;
        position: relative;
        max-width: 100%;
        margin-top: 40px;
        padding-bottom: 45px;
        overflow: hidden;

        .slick-track {
            display: flex;
        }
    }

    &--dots {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            width: 20px;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 10px;
            border: 1px solid $color1;
            border-radius: 50%;
            cursor: pointer;
            @include tablet() {
                width: 16px;
                height: 16px;
                margin: 0 2px;
            }

            button {
                text-indent: -9999px;
                width: 10px;
                height: 10px;
                padding: 0;
                border: none;
                background-color: $grey-dark;
                border-radius: 50%;
                transition: all 0.3s;
                cursor: pointer;
                @include tablet() {
                    width: 8px;
                    height: 8px;
                }
            }

            &.slick-active {
                button {
                    background-color: $color2;
                }
            }
        }
    }

    &--arrows {
        @include mq(null, 1550px) {
            display: none;
        }
    }

    &--arrow {
        position: absolute;
        top: 50%;
        z-index: 9;
        transform: translateY(-50%);
        cursor: pointer;

        &--prev {
            left: -52px;
        }

        &--next {
            right: -52px;
            transform: scale(-1) translateY(49px);
        }
    }
}