.push--links {
    position: relative;
    z-index: 1;
    margin-top: 105px;

    &--items {
        position: relative;
        max-width: 100%;
        overflow: hidden;

        .slick-track {
            display: flex;
        }
    }

    &--item {
        position: relative;
        width: 100%;
        height: 569px;
        padding-top: 85px;
        @include mobile {
            height: auto;
            padding-top: 0;
        }

        &--bg {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            @include mobile {
                display: none;
            }

            &:after,
            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }

            &:before {
                z-index: 2;
                background-color: rgba($white, 0.5);
            }

            &:after {
                z-index: 3;
                background-color: rgba($black, 0.75);
            }

            img {
                @include object(cover, 50% 100%);
            }
        }

        &--inner {
            display: flex;
            flex-wrap: wrap;
            width: $w1470;
            max-width: 100%;
            height: 324px;
            margin: 0 auto;
            overflow: hidden;
            padding: 0 15px;
            @include mobile {
                height: auto;
            }
        }

        &--image {
            width: 50%;
            height: 324px;
            @include mobile {
                width: 100%;
                height: 200px;
            }

            img {
                @include object(cover, 50% 100%);
            }
        }

        &--content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 50%;
            background-color: $white;
            padding: 20px;
            @include mobile {
                width: 100%;
            }
        }

        &--txt {
            text-align: center;

            &--1 {
                @include yanone(700);
                color: $color1;
                @include font-size-lh(55, 55);
                @include tablet() {
                    @include yanone35(700, $color1);
                }
            }

            &--2 {
                @include cabin15(400, $color1);
                margin-top: 25px;
            }
        }

        &--cta {
            margin-top: 20px;
        }
    }

    &--nav {
        display: flex;
        position: absolute;
        bottom: -140px;
        left: 50%;
        z-index: 9999;
        transform: translateX(-50%);
        width: 100%;
        max-width: 960px;
        padding: 10px 5px;
        background-color: $white;
        @include tablet {
            max-width: 95%;
        }
        @include mobile {
            display: none;
        }

        &--item {
            width: 25%;
            height: 204px;
            border: 1px solid $white;
            border-bottom-width: 6px;
            margin: 0 5px;
            cursor: pointer;
            text-decoration: none;
            transition: all 0.3s;

            &.active,
            &:hover {
                border-color: $color2;
            }

            &--image {
                width: 100%;
                height: 107px;

                img {
                    @include object(cover, 50% 100%);
                }
            }

            &--txt {
                text-align: center;
                margin-top: 10px;

                &--1 {
                    @include yanone25(700, $color1);
                    padding: 0 10px;
                    @include tablet {
                        @include yanone20(700, $color1);
                    }
                }
            }
        }
    }

    &--arrows {
        @include mq(768px, 1550px) {
            display: none;
        }
    }

    &--arrow {
        $top: calc(-50% - 44px);
        position: absolute;
        top: 50%;
        z-index: 999;
        transform: translateY(#{$top});
        cursor: pointer;
        @include mobile {
            top: calc(100% / 3);
            transform: none;
        }

        &--prev {
            left: 165px;
            @include mobile {
                left: 0;
            }
        }

        &--next {
            right: 165px;
            transform: scale(-1) translateY(92px);
            @include mobile {
                right: 0;
                transform: scale(-1);
            }
        }

        svg {
            path {
                stroke: $color1;
                @include mobile-up {
                    stroke: $white;
                }
            }
            @include mobile {
                transform: scale(0.33);
            }
        }
    }
}