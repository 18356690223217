$sans-serif: Tahoma, Arial, sans-serif;
$serif: "Times New Roman", Times, serif;
$arial: Arial, Tahoma, sans-serif;;
$times: $serif;
@mixin arial($font-weight: 400) {
    font-family: $arial;
    font-weight: $font-weight;
}
@mixin times($font-weight: 400) {
    font-family: $times;
    font-weight: $font-weight;
}
$cabin: 'Cabin', $sans-serif;
@mixin cabin($font-weight: 400) {
    font-family: $cabin;
    font-weight: $font-weight;
}
$yanone: 'Yanone Kaffeesatz', $sans-serif;
@mixin yanone($font-weight: 400) {
    font-family: $yanone;
    font-weight: $font-weight;
}

@mixin cabin12($font-weight: 400, $color: $black) {
    @include cabin($font-weight);
    @include font-size-lh(12, 15);
    color: $color;
}
@mixin cabin14($font-weight: 400, $color: $black) {
    @include cabin($font-weight);
    @include font-size-lh(14, 15);
    color: $color;
}
@mixin cabin15($font-weight: 400, $color: $black) {
    @include cabin($font-weight);
    @include font-size-lh(15, 18);
    color: $color;
}
@mixin cabin16($font-weight: 400, $color: $black) {
    @include cabin($font-weight);
    @include font-size-lh(16, 18);
    color: $color;
}
@mixin cabin17($font-weight: 400, $color: $black) {
    @include cabin($font-weight);
    @include font-size-lh(17, 20);
    color: $color;
}
@mixin cabin20($font-weight: 400, $color: $black) {
    @include cabin($font-weight);
    @include font-size-lh(20, 24);
    color: $color;
}
@mixin yanone120($font-weight: 400, $color: $black) {
    @include yanone($font-weight);
    @include font-size-lh(120, 45);
    color: $color;
    @include letter-spacing(50);
}
@mixin yanone70($font-weight: 400, $color: $black) {
    @include yanone($font-weight);
    @include font-size-lh(70, 50);
    color: $color;
    @include letter-spacing(50);
}
@mixin yanone55($font-weight: 400, $color: $black) {
    @include yanone($font-weight);
    @include font-size-lh(55, 45);
    color: $color;
    @include letter-spacing(50);
}
@mixin yanone45($font-weight: 400, $color: $black) {
    @include yanone($font-weight);
    @include font-size-lh(45, 45);
    color: $color;
    @include letter-spacing(70);
}
@mixin yanone40($font-weight: 400, $color: $black) {
    @include yanone($font-weight);
    @include font-size-lh(40, 45);
    color: $color;
    @include letter-spacing(50);
}
@mixin yanone35($font-weight: 400, $color: $black) {
    @include yanone($font-weight);
    @include font-size-lh(35, 45);
    color: $color;
}
@mixin yanone30($font-weight: 400, $color: $black) {
    @include yanone($font-weight);
    @include font-size-lh(30, 28);
    color: $color;
    @include letter-spacing(45);
}
@mixin yanone25($font-weight: 400, $color: $black) {
    @include yanone($font-weight);
    @include font-size-lh(25, 25);
    color: $color;
    @include letter-spacing(50);
}
@mixin yanone22($font-weight: 400, $color: $black) {
    @include yanone($font-weight);
    @include font-size-lh(22, 20);
    color: $color;
    @include letter-spacing(100);
}
@mixin yanone20($font-weight: 400, $color: $black) {
    @include yanone($font-weight);
    @include font-size-lh(20, 20);
    color: $color;
    @include letter-spacing(50);
}
@mixin yanone18($font-weight: 400, $color: $black) {
    @include yanone($font-weight);
    @include font-size-lh(18, 20);
    color: $color;
    @include letter-spacing(50);
}
@mixin yanone15($font-weight: 400, $color: $black) {
    @include yanone($font-weight);
    @include font-size-lh(15, 15);
    color: $color;
    @include letter-spacing(50);
}
@mixin yanone12($font-weight: 400, $color: $black) {
    @include yanone($font-weight);
    @include font-size-lh(12, 12);
    color: $color;
    @include letter-spacing(50);
}
