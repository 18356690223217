$topFooter: 180;
.footer {
    background-color: $color1;
    position: relative;
    z-index: 50;
    @include tablet-up() {
        margin-top: #{$topFooter}px;

        &:before {
            content: '';
            position: absolute;
            top: -#{$topFooter}px;
            left: 0;
            width: 100%;
            height: #{$topFooter}px;
            background-color: $white;
        }
    }

    .body--product--configurator &,
    body.product & {
        @media print {
            display: none;
        }
    }

    &--inner {
        display: flex;
        justify-content: space-between;
        padding: 45px 0 55px 0;
        @include tablet() {
            flex-direction: column-reverse;
            padding: 30px 15px;
        }
    }

    &--logo-rs {
        width: 425px;
        padding-right: 90px;
        @include tablet() {
            width: 100%;
            padding: 0;
        }
    }

    &--logo {
        position: absolute;
        top: -35px;
        @include tablet() {
            display: none;
        }

        svg {
            width: 100%;
        }
    }

    &--rs {
        margin-top: 90px;
        position: relative;

        &--header {
            text-align: center;
            &--1 {
                @include yanone20(700, $white);
                text-transform: uppercase;
            }
        }

        &--items {
            display: flex;
            justify-content: center;
            margin-top: 15px;
        }

        &--item {
            margin: 0 12px;

            &--link {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 39px;
                height: 39px;
                background-color: $color2;
                border-radius: 50%;
            }
        }
    }

    &--links {
        width: calc(100% - 425px);
        @include tablet() {
            width: 100%;
        }

        &--level {
            display: flex;
            flex-wrap: wrap;
            list-style: none;
            margin: 0;
            padding: 0;
        }

        &--item {
            width: calc(100% / 3 - 100px);
            margin: 0 50px;
            @include small-desktop() {
                width: calc(100% / 2 - 60px);
                margin: 0 30px;
            }
            @include tablet() {
                width: 100%;
                margin: 0;
            }

            @include tablet-up() {
                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3) {
                    border-top: 1px solid $white;
                }
            }
        }

        &--link {
            @include yanone22(400, $white);
            text-decoration: none;
            display: flex;
            width: 100%;
            padding: 15px 0 20px 0;
            border-bottom: 1px solid $white;
            cursor: pointer;
        }

    }

    &--bottom {
        background-color: $white;
        padding: 20px 0;
        @include tablet() {
            padding: 20px 15px;
        }
    }

    &--legals {
        @include cabin15(400, $color1);
        text-align: center;

        a {
            @include cabin15(400, $color1);
            text-decoration: none;
        }
    }
}