.page--product {
    padding-top: 15px;
}

.product {
    &--heading {
        @include tablet() {
            padding: 0 15px;
        }

        .product--willow-pack & {
            @include tablet-up() {
                position: fixed;
                top: 220px;
                left: 0;
                right: 0;
                z-index: 10;
                background-color: $white;
                padding-top: 10px;
                padding-bottom: 55px;
                transition: all 0.3s;
            }
            @media print {
                position: relative;
                top: initial;
            }

            @include tablet-up() {
                .body--product--configurator--open & {
                    z-index: 99;
                }
            }
        }

        &--title {
            display: flex;
            justify-content: center;
            align-items: center;

            h1,
            .h1 {
                @include yanone(700);
                @include font-size-lh(70, 70);
                color: $color1;
                text-transform: uppercase;
                @include letter-spacing(50);
                margin: 0;
                @include tablet() {
                    @include font-size-lh(45, 45);
                }
                @include mobile() {
                    @include font-size-lh(35, 35);
                }

                span {
                    color: $color2;
                }
            }

            &--category {
                @include yanone(700);
                @include font-size-lh(70, 70);
                color: $color1;
                text-transform: uppercase;
                @include letter-spacing(50);
                margin: 0 0 0 20px;
                @include tablet() {
                    @include font-size-lh(45, 45);
                }
                @include mobile() {
                    @include font-size-lh(35, 35);
                }
            }
        }

        &--actions {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            margin-top: 65px;
            @media print {
                display: none;
            }
            @include mobile() {
                display: none;
            }
        }
    }

    &--actions {
        width: 250px;
        margin: 0 15px 30px 0;

        &--back {
            margin: 0;
        }

        .picto-cart {
            height: 25px;

            .cls-1 {
                fill: $white;
            }
        }

        .cta--grey {
            .picto-cart {
                .cls-1 {
                    fill: $color1;
                }
            }
        }

        .picto-ask-a-quote {
            .cls-1,
            .cls-2 {
                fill: $color1;
            }
        }

        .product--resume--actions & {
            margin: 10px 0;
        }

        &--add-to-cart {
            position: relative;

            &:hover {
                .product--actions--add-to-cart--tooltip {
                    z-index: 1000;
                    opacity: 1;
                    visibility: visible;
                }
            }

            &--tooltip {
                @include cabin12(400, $white);
                background-color: $color2;
                border-radius: 10px;
                width: 100%;
                position: absolute;
                bottom: -50px;
                left: 0;
                padding: 10px;
                z-index: -1;
                opacity: 0;
                visibility: hidden;
            }
        }

        &--ask-a-quote {
            position: relative;

            &:hover {
                .product--actions--ask-a-quote--tooltip {
                    z-index: 1000;
                    opacity: 1;
                    visibility: visible;
                }
            }

            &--tooltip {
                @include cabin12(400, $white);
                background-color: $color1;
                border-radius: 10px;
                width: 100%;
                position: absolute;
                bottom: -80px;
                left: 0;
                padding: 10px;
                z-index: -1;
                opacity: 0;
                visibility: hidden;
            }
        }

        &--configurator {
            display: flex;
            flex-wrap: wrap;
            margin-top: 20px;
            @media print {
                display: none;
            }
        }

        &--print {
            cursor: pointer;
        }
    }

    &--content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @media print {
            flex-direction: column;
        }
        @include tablet() {
            padding: 0 15px;
        }

        .product--willow-pack & {
            @include tablet-up() {
                margin-top: 45px;
                padding-top: 165px;
            }
            @media print {
                margin-top: 0;
                padding-top: 0;
            }
        }

        &--col {
            &--left {
                width: 62.5%;
                padding-right: 50px;
                @media print {
                    width: 100%;
                    padding-right: 0;
                }
                @include small-desktop(null, $w1024) {
                    width: 100%;
                    padding-right: 0;
                }

                .product--willow-pack & {
                    width: 60%;
                    @media print {
                        width: 100%;
                        padding-right: 0;
                    }
                    @include tablet() {
                        width: 100%;
                    }

                    &--inner {
                        @include tablet-up() {
                            position: sticky;
                            top: 345px;
                        }
                    }
                }
            }

            &--right {
                width: 37.5%;
                margin-top: 20px;
                @media print {
                    width: 100%;
                    margin-top: 0;
                }
                @include small-desktop(null, $w1024) {
                    width: 100%;
                }

                .product--willow-pack & {
                    width: 40%;
                    margin-top: 0;
                    @media print {
                        display: none;
                    }
                    @include tablet() {
                        width: 100%;
                    }
                }
            }
        }

    }

    &--images {
        overflow: hidden;
        position: relative;
        padding-bottom: 55px;

        .slick-initialized .slick-slide {
            height: initial;
        }

        .slick-track,
        .slick-slide {
            @media print {
                width: 100% !important;
            }
        }

        .slick-slide {
            @media print {
                display: none;
                opacity: 0 !important;
            }

            &.slick-current,
            &#slick-slide00 {
                @media print {
                    display: block;
                    opacity: 1 !important;
                }
            }
        }

        .product--willow-pack & {
            padding-bottom: 0;
        }

        &--dots {
            position: absolute;
            bottom: 25px;
            left: 50%;
            transform: translateX(-50%);
            display: flex;
            margin: 0;
            list-style: none;
            @media print {
                display: none !important;
            }
            @include mobile() {
                display: none !important;
            }

            li {
                width: 58px;
                height: 3px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0 3px;
                border: none;
                cursor: pointer;

                button {
                    text-indent: -9999px;
                    width: 58px;
                    height: 3px;
                    padding: 0;
                    border: none;
                    background-color: $grey;
                    transition: all 0.3s;
                    cursor: pointer;
                }

                &.slick-active {
                    button {
                        background-color: $color2;
                    }
                }
            }
        }

        &--arrows {
            @media print {
                display: none;
            }
            @include mobile() {
                display: none;
            }
        }

        &--arrow {
            position: absolute;
            top: 33.333%;
            z-index: 999;
            transform: translateY(-33.333%);
            cursor: pointer;

            &--prev {
                left: 0
            }

            &--next {
                right: 0;
                transform: scale(-1) translateY(49px);
            }
        }

        &--zoom {
            position: absolute;
            top: 23px;
            left: 20px;
            z-index: 999;
            cursor: pointer;
            @media print {
                display: none;
            }
        }

        &--tooltip {
            position: absolute;
            bottom: 95px;
            left: 10px;
            z-index: 999;
            @media print {
                display: none;
            }
            @include mobile() {
                bottom: 40px;
                right: 0;
                left: initial;
            }

            .product--willow-pack & {
                bottom: 33px;
                left: 20px;
            }

            .picto-tooltip {
                width: 25px;
                height: 25px;
            }

            &:hover {
                .product--images--tooltip--txt {
                    opacity: 1;
                    visibility: visible;
                    display: block;
                }
            }

            &--txt {
                @include cabin15(400, $color1);
                font-style: italic;
                background-color: rgba($white, 0.85);
                box-shadow: 0 3px 6px rgba($black, 0.16);
                padding: 15px 20px;
                position: absolute;
                bottom: 7px;
                left: 7px;
                z-index: -1;
                width: 280px;
                opacity: 0;
                visibility: hidden;
                display: none;
                transition: all 0.3s;
            }
        }

        &--cta {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 230px;
            @media print {
                display: none;
            }
        }

        &--nav {
            position: relative;
            margin-top: 20px;
            padding: 0 20px;
            @media print {
                display: none;
            }

            &--items {
                overflow: hidden;
            }

            &--item {
                display: inline-block;
                vertical-align: top;
                white-space: normal;
                aspect-ratio: 1.76;
                margin-right: 10px;
                border: 1px solid $grey;
                border-top-color: $white;
                border-bottom-color: $white;
                cursor: pointer;
                transition: all 0.3s;

                &.slick-slide {
                    height: initial;
                }

                &.slick-current {
                    border-color: $color2;
                }

                img {
                    @include object();
                }
            }

            &--arrows {
                display: none;
                @media print {
                    display: none;
                }
                @include mobile() {
                    display: block;
                }
            }

            &--arrow {
                position: absolute;
                top: 50%;
                z-index: 999;
                transform: translateY(-33.333%);
                cursor: pointer;

                &--prev {
                    left: 0;
                }

                &--next {
                    right: 0;
                    transform: scale(-1) translateY(33.333%);
                }

                .cls-1 {
                    fill: $grey;
                }
            }
        }
    }

    &--image {
        display: inline-block;
        vertical-align: top;
        white-space: normal;
        width: 100%;
        aspect-ratio: 1.8;

        .product--willow-pack & {
            height: 395px;
            border-top: 1px solid $grey;
            border-bottom: 1px solid $grey;
            @media print {
                border: none;
            }
        }

        img {
            @include object(contain);
            width: 100%;
            height: 100%;
        }
    }

    &--help-delay {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid $grey;
        border-bottom: 1px solid $grey;
        padding: 15px 0;
        margin-top: 15px;
        @media print {
            display: none;
        }
    }

    &--help,
    &--delay {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        flex: 0 1 auto;

        &--picto {
            margin-right: 10px;
        }

        &--txt {
            display: flex;

            &--1 {
                @include yanone18(700, $color2);
                @include mobile() {
                    flex: 1 0 auto;
                }
            }

            &--2 {
                @include yanone18(700, $color1);
                margin-left: 10px;

                a {
                    @include yanone18(700, $color1);
                }
            }
        }
    }

    &--help {
        &--txt {
            margin-left: 10px;
        }
        @include mobile-up() {
            padding-right: 10px;

            &--txt--2:first-child {
                margin-left: 0;
            }
        }
    }

    &--delay {
        border-left: 1px solid $grey;
        padding-left: 10px;
        @include mobile() {
            border-top: 1px solid $grey;
            border-left: none;
            padding-top: 10px;
            padding-left: 0;
            margin-top: 15px;
        }
    }

    &--prices--inner {
        display: flex;
        align-items: center;

        .product--resume--prices & {
            justify-content: center;
        }

        .product--willow-pack & {
            justify-content: flex-end;
        }
    }

    &--price {
        @include yanone45(300, $color2);
        padding: 10px;
        transition: all 0.3s;
        @include tablet() {
            @include yanone40(300, $color2);
        }
        @include mobile() {
            @include yanone35(300, $color2);
        }

        span {
            @include yanone25(300, $color2);
            @include tablet() {
                @include yanone22(300, $color2);
            }
            @include mobile() {
                @include yanone18(300, $color2);
            }
        }

        .product--resume--prices & {
            @include yanone35(300, $color2);
            padding: 5px;
            @include tablet() {
                @include yanone22(300, $color2);
                padding: 0;
                margin-left: 10px;
            }
        }

        .product--willow-pack & {
            padding: 0;
        }

        &--old {
            @include yanone40(300, $color1);
            text-decoration: line-through;
            margin-left: 25px;

            .product--resume--prices & {
                @include yanone22(300, $grey-dark);
                margin-left: 10px;
            }
        }

        &--new {
            color: $white;
            background-color: $color2;

            .product--resume--prices & {
                color: $white;
            }
        }

        &--lld {
            @include yanone20(700, $color1);
            text-transform: uppercase;
            margin-top: 10px;
            margin-left: 15px;

            &--hidden {
                display: none;
            }

            .product--resume--prices &,
            .body--product--configurator & {
                @include yanone18(700, $color1);
                text-align: center;
                margin-top: 5px;
                margin-left: 0;
            }

            a {
                color: $color2;

                .product--resume--prices & {
                    color: $color2;
                }
            }
        }

        &--initial {
            @include yanone20(700, $color1);
            text-transform: uppercase;
            border-top: 1px solid $grey;
            border-bottom: 1px solid $grey;
            padding: 10px;
            margin-top: 10px;
            transition: all 0.3s;

            &--hidden {
                display: none;
            }

            span {
                color: $color2;
            }
        }
    }

    &--comment-note {
        display: flex;
        margin-top: 10px;
        margin-left: 15px;
    }

    &--star-content {
        display: flex;
        align-items: center;
        margin-left: 7px;

        .star {
            width: 17px;
        }

        .picto-star {
            transform: scale(1.4);
        }
    }

    &--comment {
        @include cabin15(400, $color1);
        text-decoration: underline;
        margin-left: 7px;
        cursor: pointer;
    }

    &--attributes {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        border-top: 1px solid $grey;
        padding-top: 25px;
        @media print {
            display: none;
        }

        &--title {
            @include yanone30(700, $color1);
            text-transform: uppercase;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 270px;
            height: 53px;
            background-color: $grey;
            border-top-right-radius: 53px;
            border-bottom-right-radius: 53px;
        }
    }

    &--attribute {
        display: flex;
        flex-wrap: wrap;
        margin-top: 30px;
        padding: 0;
        border: none;

        .product--willow-pack & {
            margin-top: 20px;
            flex-direction: column;
            align-items: flex-start;
        }

        &--label {
            @include yanone20(700, $color1);
            text-transform: uppercase;
            width: calc(100% / 3);
            @include mobile() {
                width: 100%;
                margin-bottom: 10px;
            }

            .product--willow-pack & {
                margin-bottom: 10px;
            }
        }

        &--list {
            width: calc(100% / 3 * 2);
            @include mobile() {
                width: 100%;
            }

            .product--willow-pack & {
                width: 100%;
            }
        }

        &--colors {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            list-style-type: none;
            margin: 0;
            padding: 0;
        }

        &--color {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 36px;
            height: 36px;
            border: 1px solid $grey;
            border-radius: 50%;
            margin-bottom: 12px;
            margin-right: 12px;
            transition: all 0.3s;

            &.configured {
                border-color: $color1;
            }

            &.selected {
                border-color: $color2;
            }

            a {
                width: 24px;
                height: 24px;
                border-radius: 50%;
                overflow: hidden;
            }
        }

        &--select {
            @include cabin15(400, $color1);
            appearance: none;
            border: none;
            width: 100%;
            height: 100%;
            padding-right: 65px;
            padding-left: 10px;

            &--group {
                max-width: 365px;
                width: 100%;
                height: 35px;
                border: 1px solid $color1;
                position: relative;

                .product--willow-pack & {
                    max-width: 100%;
                }

                .picto-pagination {
                    position: absolute;
                    top: 50%;
                    right: 10px;
                    transform: rotate(-90deg) translateX(100%) scale(0.8);
                    pointer-events: none;

                    .cls-1 {
                        fill: $color1;
                    }
                }
            }
        }
    }

    &--description {
        .product--details & {
            margin-top: 75px;
            @include tablet() {
                padding: 0 15px;
            }

            &:first-child {
                margin-top: 0;
            }

            body:not(.body--product--configurator) & {
                @media print {
                    display: none;
                }
            }
        }

        &--configurator {
            max-width: 820px;
        }

        &--short,
        &--long {
            p,
            ul,
            div {
                @include cabin15(400, $color1);
                margin: 20px 0;

                span {
                    @include cabin15(400, $color1);

                    &[data-href] {
                        color: $color2;
                        text-decoration: underline;
                        cursor: pointer;
                    }
                }

                b,
                strong {
                    @include cabin15(700, $color1);
                }

                a,
                a > * {
                    color: $color2;
                }
            }
        }

        &--content {
            border-bottom: 1px solid $grey;
            margin-bottom: 25px;
            padding-bottom: 25px;
            @media print {
                border: none;
                padding-bottom: 0;
            }
        }

        &--short {
            @media print {
                display: block;
            }
        }

        &--short--title {
            @include yanone45(400, $color2);
            @include tablet() {
                @include yanone35(400, $color2);
            }
            @include mobile() {
                @include yanone30(400, $color2);
            }
        }

        &--title {
            @include yanone45(400, $color2);
            @include tablet() {
                @include yanone35(400, $color2);
            }
            @include mobile() {
                @include yanone30(400, $color2);
            }
        }

        &--long{
            &--item {
                padding-top: 35px;
            }

            &--title {
                @include yanone22(700, $color1);
                text-transform: uppercase;
                padding-bottom: 5px;
                margin: 0;
            }

            &--main {
                padding-top: 10px;
                p,
                ul,
                div {
                    @include cabin15(700, $color2);
                    margin: 10px 0;

                    span {
                        @include cabin15(700, $color2);
                    }

                    b,
                    strong {
                        @include cabin15(700, $color2);
                    }

                    a {
                        color: $color1;
                    }
                }
            }

            iframe {
                max-width: 100%;
            }
        }

        .span-title {
            text-transform: uppercase;
            font-weight: 700;
        }

        .bullet-points {
            padding: 0;
            list-style: none;

            li {
                padding: 10px 0;

                span {
                    @include yanone20(700, $color1);
                    text-transform: uppercase;
                }
            }
        }
    }

    &--details-resume {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 60px;
        padding-bottom: 140px;
        @media print {
            padding-bottom: 0;
            margin-top: 0;
            page-break-before: always;
        }
        @include tablet() {
            padding-bottom: 0;
        }
    }

    $widthResume: 36%;
    &--resume {
        position: relative;
        width: $widthResume;
        padding-right: 30px;
        @media print {
            display: none;
        }
        @include tablet() {
            width: 100%;
            padding-right: 0;
        }

        &--inner {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: sticky;
            top: 180px;
        }

        &--sticky {
            @include tablet() {
                display: none;
                align-items: center;
                position: fixed;
                top: 70px;
                left: 0;
                width: 100%;
                background-color: $white;
                padding: 5px 15px;
                box-shadow: 0 3px 6px rgba($black, 0.16);
                transition: all 0.3s;

                &--show {
                    display: flex;
                }

                &--title-price {
                    display: flex;
                    align-items: center;
                    flex-grow: 1;
                }
            }
        }

        &--title {
            @include yanone55(700, $color1);
            line-height: 55px;
            text-align: center;
            text-transform: uppercase;
            @include tablet() {
                @include yanone20(700, $color1);
            }
        }

        &--category {
            @include yanone55(700, $color2);
            line-height: 55px;
            text-align: center;
            text-transform: uppercase;
            @include tablet() {
                @include yanone20(700, $color2);
            }
        }

        &--image {
            width: 450px;
            margin: 0 auto;
            @include tablet() {
                flex: 0 0 65px;
            }

            img {
                @include object();
            }
        }
    }

    &--details {
        width: calc(100% - #{$widthResume});
        border-left: 1px solid $grey;
        padding-left: 85px;
        margin-top: 45px;
        @media print {
            width: 100%;
            border-left: none;
            padding-left: 0;
            margin-top: 0;
        }
        @include tablet() {
            width: 100%;
            padding-left: 0;
            border-left: none;
        }

        .product--willow-pack & {
            width: 100%;
            border-left: none;
            padding-top: 115px;
            padding-left: 0;
            margin-top: 0;
            background-color: $white;
            position: relative;
            z-index: 50;
            @media print {
                padding-top: 45px;
            }
        }
    }

    &--warranty-insurance {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 40px;
        @media print {
            display: none;
        }
        @include tablet() {
            padding: 0 15px;
        }
    }

    &--neat-mobility {
        margin-top: 15px;
        @media print {
            display: none;
        }

        &--txt {
            @include yanone18(700, $color2);
            a {
                color: $color1;
            }
        }
    }

    &--warranty,
    &--insurance {
        width: calc(50% - 10px);
        @include tablet() {
            width: 100%;
            &:not(:last-child) {
                margin-bottom: 30px;
            }
        }

        &--txt {
            @include cabin15(400, $color1);
            font-style: italic;

            a {
                @include cabin15(400, $color1);
            }

            img {
                width: 45px;
                height: 27px;
                margin-left: 5px;
            }
        }

        &--image {
            width: 100%;
            aspect-ratio: 1.8;
            margin-top: 85px;
            @include tablet() {
                margin-top: 15px;
            }

            img {
                @include object();
            }
        }
    }

    &--specifications {
        margin-top: 75px;
        &:first-child {
            margin-top: 0;
        }
        @include tablet() {
            padding: 0 15px;
        }
        @media print {
            margin-top: 25px;
            padding: 0;
            &:first-child {
                margin-top: 0;
            }
        }

        &--txt {
            @include cabin15(400, $color1);
            font-style: italic;
            text-align: justify;
        }

        &--items {
            width: 100%;
            margin-top: 30px;
            column-count: 2;
            column-gap: 40px;
            @include tablet() {
                column-count: 1;
                column-gap: 0;
                @media print {
                    column-count: 2;
                    column-gap: 20px;
                }
            }
        }

        &--item {
            width: 100%;
            max-width: 395px;
            margin-bottom: 30px;
            break-inside: avoid;
        }

        &--category {
            display: flex;
            align-items: center;

            &--picto {
                width: 30px;
                margin-right: 10px;
            }

            &--name {
                @include yanone20(700, $color1);
                text-transform: uppercase;
            }
        }

        &--value {
            @include cabin15(400, $color1);
            padding: 1px 5px 1px 10px;
            transition: all 0.3s;

            &--default {
                font-weight: 700;
                background-color: $color3;
            }
        }
    }

    &--reviews {
        background-color: $bg-reviews;
        margin-top: 70px;
        @media print {
            display: none;
        }

        &--short {
            @media print {
                display: none;
            }
        }

        &--image {
            width: 100%;

            img {
                width: 100%;
            }
        }

        &--inner {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            padding: 60px 30px;
        }

        $widthComments: 200;
        &--infos {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: #{$widthComments}px;
            @include tablet() {
                width: 100%;
            }

            &--note {
                @include yanone45(300, $color2);

                span {
                    @include yanone120(700, $color2);
                }
            }

            &--star {
                .star {
                    margin: 0 5px;
                }

                .star,
                .picto-star {
                    transform: scale(1.2);
                }
            }
        }

        &--content {
            width: calc(100% - #{$widthComments}px);
            @include tablet() {
                width: 100%;
            }
        }

        &--title {
            @include yanone45(400, $color2);
            @include tablet() {
                display: none;
            }
        }

        &--items {
            margin-top: 20px;
        }

        &--item {
            padding: 12px 0;

            &:not(:first-child) {
                border-top: 1px solid rgba($color1, 0.25);
            }

            &--author-note {
                display: flex;
                align-items: flex-end;
            }

            &--author {
                @include yanone20(700, $color1);
            }

            &--message {
                @include cabin15(400, $color1);

                span {
                    @include cabin15(700, $color2);
                }
            }

            &--images {
                display: flex;
                flex-wrap: wrap;
                margin: 0 -15px;
            }

            &--image {
                width: calc(100% / 3 - 30px);
                aspect-ratio: 1;
                margin: 0 15px;
                padding-top: 15px;
                overflow: hidden;

                .span-fancybox {
                    cursor: pointer;
                    display: block !important;
                    height: 100%;
                }

                img {
                    @include object();
                    width: 100% !important;
                    height: 100% !important;
                }
            }
        }

        &--pagination {
            margin-top: 40px;
        }
    }

    &--accessories {
        position: relative;
        @media print {
            display: none;
        }
        @include tablet() {
        //    display: none;
            margin-bottom: 40px;
        }
    }

    &--name-actions-prices {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 15px;

        .product--name-actions,
        .product--prices {
            flex: 0 1 auto;
        }
    }

    &--name {
        @include yanone30(700, $color1);
        text-transform: uppercase;

        span {
            color: $color2;
        }
    }

    &--configurator {
        &--actions {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            width: 100%;
            max-width: 570px;
            background-color: $white;
            transition: all 0.3s;
            @include tablet-up() {
                position: fixed;
                top: 335px;
                z-index: 50;
            }

            .body--product--configurator--open & {
                @include tablet-up() {
                    z-index: 99;
                }
            }

            &--picto {
                margin-right: 7px;
            }

            &--name {
                @include yanone20(700, $color2);
                text-transform: uppercase;
            }
        }

        &--action {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-right: 25px;
            cursor: pointer;
        }

        &--items {
            border-top: 1px solid $color1;
            margin-top: 50px;
        }

        &--item {
            width: 100%;
            min-height: 60px;
            padding: 10px 15px 10px 5px;
            border-bottom: 1px solid $color1;
            transition: all 0.3s;

            &--hidden {
                display: none;
            }

            &--inner {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
            }

            $configuratorRight: 155;
            $widthPicto: 40;

            &--left {
                display: flex;
                align-items: center;
                width: calc(100% - #{$widthPicto}px - #{$configuratorRight}px);
                height: 100%;
            }

            &--right {
                display: flex;
                align-items: center;
                width: #{$configuratorRight}px;
                height: 100%;
            }

            &--picto {
                width: #{$widthPicto}px;
                height: 100%;
                background-color: $color2;

                img {
                    @include object(contain);
                    height: 100%;
                    image-rendering: -webkit-optimize-contrast;
                }
            }

            &--category-name {
                @include yanone20(700, $color1);
                text-transform: uppercase;
                margin-left: 15px;
                //flex-grow: 1;
            }

            &--product-name {
                @include cabin16(400, $color2);
                line-height: 19px;
                margin-left: 15px;
                @include ellipsis();
                //flex: 0 1 100%;
            }

            &--price {
                @include cabin16(400, $color1);
                text-align: right;
                width: 110px;
                margin-left: 15px;
            }

            &--action {
                width: 15px;
                margin-left: 15px;

                .picto-pagination {
                    transform: rotate(-90deg) scale(0.8);
                    transition: all 0.3s;

                    .cls-1 {
                        fill: $color1;
                    }
                }

                &--open {
                    .picto-pagination {
                        transform: rotate(90deg) scale(0.8);
                    }
                }
            }
        }

        &--attributes {
            border-top: 1px solid $grey;
            display: none;
            transition: all 0.3s;
            position: relative;

            &--multi {
                flex-wrap: wrap;
            }

            &--open {
                z-index: 55;
                display: block;
                background-color: $white;

                &.product--configurator--attributes--multi {
                    display: flex;
                    padding-bottom: 25px;
                }
            }

            &--item {
                .product--configurator--attributes--multi & {
                    width: calc(33.333% - 30px);
                    margin: 25px 15px 0 15px;
                    border: 1px solid $grey-dark;
                    border-radius: 5px;
                    transition: all 0.3s;

                    &.configured {
                        border-color: $color1;
                        .product--configurator--attributes--item--inner {
                            border-color: $color1;
                        }
                    }

                    &.selected {
                        border-color: $color2;

                        .product--configurator--attributes--item--inner {
                            border-color: $color2;
                        }
                    }
                }

                &--inner {
                    height: 100%;
                    padding: 10px 5px;

                    .product--configurator--attributes--multi & {
                        border: 1px solid $white;
                        border-radius: 5px;
                        transition: all 0.3s;
                    }
                }
            }

            &--attribute {
                &--content {
                    display: none;
                    transition: all 0.3s;

                    &--show {
                        display: block;
                    }

                    &--image {
                        width: 100%;
                        height: 90px;

                        img {
                            @include object();
                        }
                    }

                    &--title {
                        @include cabin16(400, $color1);
                        margin-top: 15px;
                        padding: 0 10px;
                    }

                    &--price {
                        @include cabin16(400, $color1);
                        text-align: right;
                        margin-top: 5px;
                        padding: 0 10px;
                    }
                }
            }

            &--combinations {
                .product--configurator--attributes--multi & {
                    background-color: $white;
                    height: 220px;
                    border: 1px solid $color1;
                    border-top: none;
                    padding: 0 15px 20px 15px;
                    overflow: hidden;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: -15px;
                    z-index: 5;
                    display: none;
                    transition: all 0.3s;

                    &--open {
                        display: block;
                    }

                    &--inner {
                        height: 140px;
                        overflow-y: auto;
                        padding-right: 45px;
                        padding-left: 15px;
                        scrollbar-width: thin;
                        scrollbar-color: $grey $grey-light;

                        &::-webkit-scrollbar {
                            width: 10px;
                        }

                        /* Track */
                        &::-webkit-scrollbar-track {
                            background: $grey-light;
                            border: 1px solid $grey;
                        }

                        /* Handle */
                        &::-webkit-scrollbar-thumb {
                            background: $grey;
                        }
                    }
                }

                &--description {
                    @include cabin16(400, $color1);

                    a {
                        @include cabin16(400, $color2);
                        text-decoration: underline;
                    }
                }
            }

            &--action {
                margin: 20px 0;

                .validation {
                    display: flex;

                    .product--configurator--attributes--item.selected & {
                        display: none;
                    }
                }

                .annulation {
                    display: none;

                    .product--configurator--attributes--item.selected & {
                        display: flex;
                    }
                }
            }
        }
    }

    &--list-pack {
        &--inner {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: $w1150;
            max-width: 100%;
            list-style: none;
            margin: 0;
            padding: 0;
            @media print {
                width: 100%;
            }
            @include tablet() {
                margin-bottom: 100px;
                padding: 0 15px;
            }
        }

        &--item {
            width: calc(50%  - 45px);
            margin-top: 30px;
            @media print {
                width: 100%;
            }
            @include tablet() {
                width: 100%;
            }
        }

        &--category {
            display: flex;
            align-items: center;

            &--picto {
                width: 30px;
                margin-right: 10px;
                background-color: $color1;

                img {
                    @include object(contain);
                    height: 100%;
                    image-rendering: -webkit-optimize-contrast;
                }
            }

            &--name {
                @include yanone20(700, $color1);
                text-transform: uppercase;
            }
        }

        &--product {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: $color3;
            padding: 1px 5px 1px 10px;

            &--name,
            &--price {
                @include cabin15(700, $color1);
            }

            &--name {
                flex: 0 1 auto;
            }

            &--price {
                text-align: right;
                flex: 1 0 auto;
                padding-left: 20px;
            }
        }
    }
}

.products-list {
    .page--category & {
        @include tablet() {
            padding: 0 15px;
        }
    }

    .promo {
        position: absolute;
        top: 0;
        left: 0;
        transform: scale(0.5) translate(-50%, -50%);
        .promo-wrap {
            position: absolute;
            top: 20px;
            left: 10px;
            .promo-price-percent-reduction {
                @include yanone30(700, $white);
            }
        }
    }

    &--categories-tree {
        &--items {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            @include tablet() {
                justify-content: flex-start;
                flex-wrap: nowrap;
                overflow: auto;
            }
        }

        &--item {
            position: relative;
            padding: 15px 25px 15px 25px;
            @include tablet() {
                flex: 1 0 auto;
            }

            &:first-child {
                padding-left: 0;
            }

            &:not(:last-child):after {
                content: '';
                background-color: $grey;
                width: 1px;
                height: 100%;
                position: absolute;
                top: 0;
                right: 0;
            }

            &--link {
                text-decoration: none;
                display: flex;
                justify-content: flex-start;
                align-items: center;
            }

            &--name {
                @include yanone20(700, $color1);
                text-transform: uppercase;

                .products-list--categories-tree--item--link:hover & {
                    color: $color2;
                }
            }

            &--picto {
                margin-left: 10px;
                transition: all 0.3s;

                .products-list--categories-tree--item--link:hover & {
                    transform: translateX(10px);

                    .picto-right .cls-1 {
                        fill: $color2;
                    }
                }
            }
        }
    }

    &--filters {
        display: flex;
        align-items: flex-end;
        margin-bottom: 40px;
        border-top: 1px solid $grey-dark;
        border-bottom: 1px solid $grey-dark;
        padding-top: 15px;
        padding-bottom: 20px;
        @include tablet() {
            margin-bottom: 20px;
        }
        @include mobile() {
            flex-wrap: wrap;
        }

        &--hidden {
            display: none;
        }

        &--items {
            flex-grow: 1;
        }

        &--count {
            @include yanone30(700, $grey-dark);
            text-align: right;
            padding-bottom: 10px;
            flex: 0 0 auto;
            @include tablet() {
                padding: 0 30px;
                margin-top: 20px;
            }
        }

        .result {
            padding: 20px 0 15px 0;

            .nb {
                display: none;
            }

            ul {
                display: flex;
                flex-wrap: wrap;
                padding: 0;
                margin: 5px 0 0 0;
                list-style: none;

                li {
                    @include cabin15(400, $color1);
                    margin: 10px;
                    display: flex;
                    align-items: center;

                    a {
                        color: $color2;
                        text-align: center;
                        text-decoration: none;
                        width: 23px;
                        height: 23px;
                        margin-right: 5px;
                        border: 1px solid $color2;
                    }

                    &.reset_all {
                        a {
                            text-decoration: underline;
                            border: none;
                            width: auto;
                            height: auto;
                        }
                    }
                }
            }
        }
    }


    &--items {
        #PM_ASearchResultsInner {
            display: flex;
            flex-wrap: wrap;
        }
    }

    &--product {
        border-top: 1px solid $grey-dark;
        border-right: 1px solid $white;
        border-bottom: 1px solid $grey-dark;
        border-left: 1px solid $white;
        padding: 0 10px 10px 10px;
        position: relative;
        transition: all 0.3s;

        &:hover {
            border: 1px solid $color2;
        }

        .products-list--items & {
            width: calc(100% / 3 - 20px);
            margin: 40px 10px;
            @include small-desktop() {
                width: calc(100% / 2 - 20px);
            }
            @include tablet() {
                margin: 20px 10px;
            }
            @include mobile() {
                width: 100%;
                margin: 20px 0;
            }

            &:nth-child(3n+1) {
                margin-left: 0;
            }

            &:nth-child(3n) {
                margin-right: 0;
            }
        }

        .featured-products &,
        .product--accessories &{
            display: inline-block;
            vertical-align: top;
            white-space: normal;
            margin: 0 17px;
            @include mobile() {
                margin: 0;
            }
        }

        &--link {
            text-decoration: none;
        }

        &--image {
            width: 100%;
            aspect-ratio: 1.77;

            img {
                @include object();
            }
        }

        &--txt {
            &--1 {
                @include yanone20(700, $color1);
                text-transform: uppercase;
                margin: 15px 0 0 0;
            }

            &--2 {
                @include cabin15(400, $color1);
                margin: 10px 0 0 0;
            }
        }

        &--prices {
            margin: 15px 0;

            &--inner {
                display: flex;
                align-items: baseline;
            }
        }

        &--price {
            @include yanone35(300, $color2);
            transition: all 0.3s;
            padding: 5px 0;
            flex: 1 0 auto;

            &--old {
                @include yanone30(300, $color2);
                text-decoration: line-through;
                margin-bottom: 10px;
            }

            &--new {
                background-color: $color2;
                color: $white;
                padding: 5px 10px;
            }
        }

        &--lld {
            @include yanone15(700, $color1);
            text-transform: uppercase;
            margin-left: 10px;
            flex-grow: 1;

            span {
                color: $color2;
                text-decoration: underline;
            }
        }

        &--actions {
            display: flex;
            align-items: center;
            margin-top: 15px;
        }

        &--add-to-cart {
            display: flex;
            align-items: center;
            margin-top: 15px;
        }

        &--comment-note {
            display: flex;
        }

        &--star-content {
            display: flex;
            align-items: center;
            margin-left: 7px;
        }

        &--comment {
            @include cabin15(400, $color1);
            margin-left: 7px;
        }

        &--picto {
            position: absolute;
            top: 0;
            left: 0;
            width: 70px;
        }
    }
}

.star_content {
    display: flex;

    .star {
        margin-right: 3px;

        &_on {
            .picto-star .cls-1 {
                opacity: 1;
            }
        }
    }
}

.PM_ASearchLayerBlock,
.PM_ASearchLayerResult {
    background-color: $white;
    background-image: url('#{$imgPath}/loader.gif');
    background-position: 50% 50%;
    background-repeat: no-repeat;
    display:none;
}

.PM_ASForm {
    display: flex;
    flex-wrap: wrap;

    .PM_ASCriterionsGroup {
        width: 25%;
        padding: 0 30px;
        margin-top: 30px;
        @include small-desktop() {
            width: calc(100% / 3);
        }
        @include tablet() {
            width: 50%;
            margin-top: 20px;
        }
        @include mobile() {
            width: 100%;
        }

        @include tablet-up() {
            &:nth-child(4n+1) {
                padding-left: 0;
                padding-right: 60px;
            }
        }
    }

    .PM_ASCriterionLabel {
        @include yanone20(700, $color1);
        text-transform: uppercase;
        margin-bottom: 10px;
    }

    .PM_ASCriterionGroupDivSelect {
        max-width: 345px;
        width: 100%;
        height: 35px;
        border: 1px solid $color1;
        position: relative;
        padding: 5px 0;
        > .selector {
            span {
                display: none;
            }
        }
    }

    .PM_ASCriterionGroupSelect {
        @include cabin15(400, $color1);
        appearance: none;
        background-color: transparent;
        border: none;
        width: 100%;
        height: 100%;
        padding-right: 80px;
        padding-left: 10px;
    }

    .PM_ASCritRange {
        width: 100%;
        height: 20px;
        position: relative;

        .ui-slider-range {
            width: 100%;
            height: 1px;
            border-top: 1px dashed $cta-white-hover;
            position: absolute;
            top: 10px;
            left: 0;
        }

        .ui-slider-handle {
            background: url("#{$imgPath}/pictos/picto-slider-handle.svg");
            background-position: 0 0;
            background-repeat: no-repeat;
            position: absolute;
            top: 3px;
            z-index: 2;
            width: 15px;
            height: 15px;
            cursor: default;
            border: none;
            outline: none;
            box-shadow: none;
        }
    }

    .PM_ASCritRangeValue {
        @include yanone20(700, $color1);
        text-align: center;
    }

    .picto-pagination {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: rotate(-90deg) translateX(100%);
        pointer-events: none;

        .cls-1 {
            fill: $cta-white-hover;
        }
    }
}
.fancy-config {
    padding: 15px;

    &--header {
        @include yanone25(400, $color2);
        text-align: center;
    }

    &--body {
        border-top: 1px solid $grey-dark;
    }

    &--pack {
        padding-top: 15px;

        &--title {
            @include cabin17(400, $color1);
            text-align: center;
        }

        &--cta {
            display: flex;
            justify-content: center;
            padding-top: 10px;
        }
    }
}