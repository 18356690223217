.breadcrumb {
    padding-top: 25px;
    padding-bottom: 15px;
    @include tablet() {
        padding-left: 15px;
        padding-right: 15px;
    }
    @include mobile() {
        display: none;
    }

    .fancybox-opened & {
        display: none;
    }

    .body--product--configurator & {
        position: fixed;
        top: 180px;
        left: 0;
        right: 0;
        z-index: 50;
        padding-top: 25px;
        padding-bottom: 5px;
        background-color: $white;
        transition: all 0.3s;
    }

    .body--product--configurator &,
    body.product & {
        @media print {
            display: none;
        }
    }

    .body--product--configurator--open & {
        z-index: 99;
    }

    //body.category &,
    body.contact & {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 5;
        width: 100%;
        margin: 0;
    }

    &--inner {
        display: flex;
    }

    &--link {
        text-decoration: none;

        &:hover,
        &:active,
        &:focus,
        &:focus-visible,
        &:focus-within,
        &:link,
        &:visited {
            color: $color1;
        }
    }

    &--name {
        @include cabin12(400, $color1);
        margin: 0;
        padding-right: 12px;
        position: relative;

        .breadcrumb--item:last-child & {
            color: $color2;
            font-weight: 700;

            //body.category & {
            //    color: $white;
            //}
        }

        //body.category & {
        //    color: $white;
        //}

        &:after {
            .breadcrumb--item:not(:last-child) & {
                content: ' > ';
                @include cabin12(400, $color1);
                position: absolute;
                top: 50%;
                right: 2px;
                transform: translateY(-55%);

                //body.category & {
                //    color: $white;
                //}
            }
        }
    }
}