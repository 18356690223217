.push--blog {
    margin-top: 160px;
    @include tablet() {
        margin-top: 65px;
    }

    .container {
        width: $w1260;
    }

    &--header {
        display: flex;
        align-items: baseline;
        @include tablet() {
            justify-content: space-between;
            padding: 0 15px;
        }

        &--1 {
            @include yanone70(700, $color1);
            @include tablet() {
                @include yanone55(700, $color1);
            }
        }

        &--link {
            color: $color1;
            text-decoration: underline;
            margin-left: 13px;

            &--txt {
                @include cabin15(400, $color1);
            }
        }
    }

    &--items {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 20px;
    }

    &--item {
        width: calc(100% / 3 - 60px);
        box-shadow: 0 3px 20px rgba($cta-white-hover, 0);
        transition: all 0.3s;
        @include small-desktop() {
            width: calc(100% / 3 - 40px);
        }

        &:first-child {
            width: 100%;
            margin-bottom: 75px;
            @include tablet() {
                margin-bottom: 0;
            }
        }

        &:not(:first-child) {
            @include tablet() {
                display: none;
            }
            &:hover {
                box-shadow: 0 3px 20px rgba($cta-white-hover, 0.2);
            }
        }

        &--link {
            text-decoration: none;
        }

        &--inner {
            display: flex;
            flex-direction: column;

            @include tablet-up() {
                .push--blog--item:first-child & {
                    flex-direction: row;
                }
            }
        }

        &--image {
            width: 100%;
            aspect-ratio: 1.9;
            position: relative;
            overflow: hidden;

            @include tablet-up() {
                .push--blog--item:first-child & {
                    width: calc(100% / 3 * 2);
                }
            }

            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transition: all 0.3s;

                .push--blog--item:hover & {
                    background-color: rgba($white, 0.2);
                }
            }

            img {
                @include object();
                transition: all 0.3s;

                .push--blog--item:hover & {
                    transform: scale(1.2);
                }
            }
        }

        &--content {
            position: relative;
            padding: 18px;

            @include tablet-up() {
                .push--blog--item:first-child & {
                    width: calc(100% / 3);
                    background-color: $grey-light;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 20px 61px;
                }
            }

            &:before {
                content: '';
                position: absolute;
                top: -16px;
                left: 42px;
                width: 0;
                height: 0;
                @include triangle(22, 16, top, $white);

                @include tablet-up() {
                    .push--blog--item:first-child & {
                        top: 50%;
                        left: -16px;
                        transform: translateY(-50%);
                        @include triangle(16, 22, left, $grey-light);
                    }
                }
            }
        }

        &--title {
            @include yanone30(700, $color2);

            @include tablet-up() {
                .push--blog--item:first-child & {
                    text-align: center;
                }
            }
        }

        &--description {
            @include cabin15(400, $color1);
            margin-top: 10px;
            margin-bottom: 5px;

            @include tablet-up() {
                .push--blog--item:first-child & {
                    text-align: center;
                    margin-top: 20px;
                }
            }
        }

        &--cta {
            display: none;

            .push--blog--item:first-child & {
                display: block;
                margin-top: 20px;
            }
        }
    }
}