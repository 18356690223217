.push--intro {
    position: relative;
    z-index: 9;
    @include mobile {
        background-color: $grey;
    }

    &--item {
        position: relative;
        width: 100%;
        @include mobile-up() {
            height: 738px;
        }

        &--image,
        &--video {
            display: flex;
            justify-content: center;
            width: 100%;
            height: 100%;
            background-color: $white;
            @include mobile {
                height: auto;
                background-color: $grey;
            }

            img,
            video,
            iframe {
                @include object(contain);
                @include mobile() {
                    object-position: 50% 0%;
                }
            }
        }

        &--content {
            width: $w1260;
            max-width: 100%;
            margin: 0 auto;
            padding: 20px;
            @include mobile-up() {
                position: absolute;
                top: 33%;
                left: 50%;
                transform: translateX(-50%);
                padding: 0 30px;
            }
            @include mobile() {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                justify-content: center;
            }
        }

        &--txt {
            max-width: 400px;

            &--1 {
                @include yanone(700);
                @include font-size-lh(70, 70);
                color: $white;
                text-shadow: 0 0 5px rgba($black, 0.75);
                @include mobile() {
                    @include yanone45(700, $white);
                }
            }
        }

        &--cta {
            display: flex;
            margin-top: 20px;
        }
    }

    &--actions {
        position: absolute;
        bottom: -25px;
        right: 10%;
        @include mobile() {
            display: none;
        }

        &--cta {
            margin-top: 15px;
        }
    }

    &--arrows {
        @include mq(768px, 1550px) {
            display: none;
        }
    }

    &--arrow {
        $top: calc(-50% - 44px);
        position: absolute;
        top: 50%;
        z-index: 999;
        transform: translateY(#{$top});
        cursor: pointer;
        @include mobile {
            top: 25%;
        }

        &--prev {
            left: 165px;
            @include mobile {
                left: 0;
                top: 0;
                transform: none;
            }
        }

        &--next {
            right: 165px;
            transform: scale(-1) translateY(92px);
            @include mobile {
                right: 0;
                top: 0;
                transform: scale(-1);
            }
        }

        svg {
            path {
                stroke: $color1;
            }
            @include mobile {
                transform: scale(0.5);
            }
        }
    }
}