.pagination {
    &--inner {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    &--page {
        padding: 7px 35px 3px 35px;
        border-left: 1px solid $grey;
        @include mobile() {
            padding: 7px 15px 3px 15px;
        }

        #category-content & {
            padding: 7px 25px 3px 25px;
            @include mobile() {
                padding: 7px 15px 3px 15px;;
            }
        }

        .product--reviews--pagination & {
            border-left-color: $color1;
        }

        &:nth-child(2) {
            border-left-color: $white;

            .product--reviews--pagination & {
                border-left-color: $bg-reviews;
            }
        }
    }

    &--link,
    &--nolink {
        @include yanone25(700, $grey);
        text-decoration: none;

        .product--reviews--pagination & {
            color: $color2;
        }

        .active &,
        .current &  {
            color: $color1;
        }
    }

    &--link {
        .product--reviews--pagination & {
            cursor: pointer;
        }

        .active &,
        .current &  {
            .product--reviews--pagination & {
                cursor: auto;
            }
        }
    }

    &--previous,
    &--next {
        display: flex;
        align-items: center;
        padding: 0 35px;
        @include mobile() {
            padding: 0 15px;
        }

        #category-content & {
            padding: 0 25px;
            @include mobile() {
                padding: 0 15px;
            }
        }

        .picto-pagination {
            width: 10px;
            height: 17px;
        }
    }

    &--next {
        .picto-pagination,
        img {
            transform: scale(-1);
        }
    }
}