*,
*:after,
*:before {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

.clearfix:before,
.clearfix:after {
    content: " ";
    display: table;
}

.clearfix:after {
    clear: both;
}

.alignnone {
    margin: 5px 20px 20px 0;
}

.aligncenter,
div.aligncenter {
    display: block;
    margin: 5px auto 5px auto;
}

.alignright {
    float: right;
    margin: 5px 0 20px 20px;
}

.alignleft {
    float: left;
    margin: 5px 20px 20px 0;
}

a img.alignright {
    float: right;
    margin: 5px 0 20px 20px;
}

a img.alignnone {
    margin: 5px 20px 20px 0;
}

a img.alignleft {
    float: left;
    margin: 5px 20px 20px 0;
}

a img.aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.wp-caption {
    background: $white;
    border: 1px solid $grey;
    max-width: 96%;
    padding: 5px 3px 10px;
    text-align: center;
}

.wp-caption.alignnone {
    margin: 5px 20px 20px 0;
}

.wp-caption.alignleft {
    margin: 5px 20px 20px 0;
}

.wp-caption.alignright {
    margin: 5px 0 20px 20px;
}

.wp-caption img {
    border: 0 none;
    height: auto;
    margin: 0;
    max-width: 98.5%;
    padding: 0;
    width: auto;
}

.wp-caption .wp-caption-text,
.gallery-caption {
    @include font-size-lh(11, 17);
    margin: 0;
    padding: 0 4px 5px;
}

#sb-plugin-container {
    margin-top: 5px;
}

.page.home,
.archive,
.single-actualite,
.single-conseil,
.page-template {
    #main {
        .container {
            width: $w1150;
            max-width: 100%;
            margin: 0 auto;
            padding: 0 25px;
        }
    }
}

.postid-20078 {
    #main {
        .container {
            width: $w1470;
            padding: 0 15px;
            @include tablet() {
                width: 520px;
            }
        }
        @include tablet-up() {
            .breadcrumb {
                position: absolute;
                top: 0;
                left: 50%;
                z-index: 50;
                transform: translateX(-50%);
                width: $w1470;
                max-width: 100%;
                padding: 25px 35px;
            }
        }
    }
}

.post-title {
    @include yanone40(400, $color1);
    text-transform: uppercase;
    margin: 0 0 20px 0;
}

.date {
    @include yanone(700);
    color: $white;
    @include font-size-lh(25, 36);
    position: relative;
    display: inline-block;
    height: 36px;
    margin: 0 0 25px 0;
    padding: 0 65px 0 10px;
    background-color: $color1;

    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        @include triangle(10, 36, 'left', $white);
    }
}

.image {
    &.post-thumbnail {
        text-align: center;
        margin-bottom: 50px;

        img {
            border-bottom: 5px solid $color2;
        }
    }
}

#search-content {
    padding-top: 25px;
    #search-header {
        #search-count {
            @include yanone25(700, $color2);
            margin: 0 0 10px 0;
        }

        #search-filter {
            @include yanone20(700, $color2);
        }

        #search-input {
            @include yanone20(400, $color1);
            position: relative;
            background-color: $grey;
            height: 36px;
            padding: 0 40px 0 10px;

            input {
                width: 160px;
                border: none;
                color: $white;
                background-color: $grey;
                height: 100%;
                transition: all 0.3s;

                &:focus {
                    width: 200px;
                }
            }

            button {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                border: none;
                background: none;
                margin: 0;
                padding: 0;
                width: 36px;
                height: 36px;

                i {
                    @include font-size-lh(20, 36);
                    color: $white;
                }
            }
        }
    }

    #search-result {
        ul {
            margin: 0;
            padding: 20px 0 45px 0;
            list-style: none;

            li {
                margin: 0 0 35px 0;
                padding: 0 0 35px 0;
                border-bottom: 1px solid $black;

                h2,
                .h2 {
                    @include yanone22(700, $color1);
                    margin: 0 0 10px 0;
                    padding: 0;
                    text-transform: uppercase;
                }

                h3,
                .h3 {
                    @include yanone(400);
                    color: $color1;
                    @include font-size(22.4);
                    line-height: 1;
                    margin: 0 0 25px 0;
                    padding: 0;
                    text-transform: uppercase;
                }

                h4,
                .h4 {
                    @include font-size(20.8);
                    line-height: 1;
                }

                .content {
                    @include cabin17(400, $color1);
                    position: relative;
                    margin: 0;
                    padding: 0 165px 0 0;

                    .post-content {
                        @include dotdotdot(17, 20, 2);

                        a {
                            color: $color2;
                        }
                    }

                    b, strong {
                        font-weight: 700;
                    }

                    .read {
                        position: absolute;
                        top: 0;
                        right: 0;

                        a {
                            display: inline-block;
                            @include cabin17(400, $color2);
                            padding: 10px;
                            border-radius: 2px;
                            border: 2px solid $color2;
                            background-color: $white;
                            text-transform: uppercase;
                            @include transition(all, 0.3s);
                            text-decoration: none;

                            &:hover {
                                color: $white;
                                background-color: $color2;
                            }
                        }
                    }
                }
            }
        }
    }
}

#post-content {
    padding-bottom: 60px;
}

#post-footer {
    #footer-author {
        padding-top: 50px;
        padding-bottom: 50px;
        text-align: center;
        background-color: $grey;
        color: $white;

        .author-about {
            @include font-size-lh(24, 26);
        }

        .author-name {
            @include yanone30(700, $color1);
            text-transform: uppercase;
            font-style: italic;
        }

        .author-desc {
            @include font-size-lh(18, 20);
            max-width: 732px;
            margin: auto;
        }
    }

    #footer-social {
        text-align: center;
        padding: 24px 0;

        .container {
            background-color: transparent;
        }

        .social-title {
            @include yanone40(400, $color1);
        }

        ul {
            list-style: none;
            padding: 0;

            .social {
                width: 63px;
                height: 63px;
                display: inline-block;
                margin: 0 10px;

                a,
                span {
                    color: $white;
                    background-color: $color1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    cursor: pointer;

                    img {
                        transform: scale(1.5);
                    }
                }
            }
        }
    }
}

.intro {
    @include yanone25(700, $color2);
    margin: 0 0 20px 0;

    &[itemprop="description"] {
        font-style: italic
    }
}

#flexible-content,
#flex-content {
    position: relative;
    @include cabin17(400, $color1);
    .postid-20078 & {
        @include cabin15(400, $color1);
    }

    a {
        color: $color2;
        text-decoration: underline;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
        @include yanone22(700, $color2);
        text-transform: uppercase;

        .postid-20078 & {
            @include yanone30(400, $color2);
            text-transform: initial;
        }
    }

    h2,
    .h2 {
        color: $color2;
    }

    h3,
    .h3 {
        @include font-size(22.4);
        line-height: 1;
    }

    h4,
    .h4 {
        @include font-size(20.8);
        line-height: 1;
    }

    ul {
        padding: 0;
        list-style: none;

        li {
            padding-left: 20px;
            position: relative;

            &:before,
            &:after {
                content: '';
                position: absolute;
                left: 0;
            }

            &:before {
                top: 3px;
                @include triangle(14, 14, right, $color1);
            }

            &:after {
                @include triangle(4, 14, right, $white);
                top: 3px;
            }
        }
    }

    ol {
        padding: 0;
        counter-reset: section;
        list-style: none;

        li {
            position: relative;
            padding: 0 0 0 20px;

            &:before {
                position: absolute;
                top: 0;
                left: 0;
                color: $color2;
                counter-increment: section;
                content: counters(section, ".") ".\00a0";
                display: inline-block;
                min-width: 20px;
                text-align: center;
            }
        }
    }

    hr {
        border: none;
        height: 1px;
        background-color: $color1;
    }

    blockquote {
        @include yanone18(400, $color1);
        text-align: center;
        font-style: italic;
        position: relative;
        padding: 20px 0;

        &:before,
        &:after {
            content: "";
            height: 1px;
            background-color: $black;
            position: absolute;
            left: 25%;
            right: 25%;
        }

        &:before {
            top: 0;
        }

        &:after {
            bottom: 0;
        }

        p {
            &:before {
                content: "\00AB\00a0";
            }

            &:after {
                content: "\00a0\00BB";
            }
        }
    }


    .bloc {
        margin: 25px 0;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }

        &.cols-wrapper-1 {
            .layout-video {
                width: 50%;
                margin-left: auto;
                margin-right: auto;
                @include mq(null, 1023px) {
                    width: 100%;
                }
            }
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        .h1,
        .h2,
        .h3,
        .h4,
        .h5,
        .h6,
        hr,
        p,
        ul,
        ol,
        table {
            margin: 15px 0;

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        h3,
        .h3 {
            @include font-size(22.4);
            line-height: 1;
        }

        h4,
        .h4 {
            @include font-size(20.8);
            line-height: 1;
        }

        blockquote {
            margin: 40px 0;
        }

        .titre {
            margin-bottom: 20px;
        }

        .image {
            img {
                max-width: 100%;
                height: auto;
                vertical-align: middle;
                //border-bottom: 5px solid $color2;
            }
        }

        &.img-left,
        &.txt-right {
            @include clearfix;

            .text {
                float: right;
            }

            .image {
                float: left;
            }
        }

        &.img-right,
        &.txt-left {
            overflow: hidden;

            .text {
                float: left;
            }

            .image {
                float: right;
            }
        }

        @for $i from 2 through 8 {
            &.img#{$i}0 {
                .image {
                    width: #{$i}0%;
                }
            }
        }
        @for $i from 2 through 8 {
            &.txt#{$i}0 {
                .text {
                    width: #{$i}0%;
                }
            }
        }

        &.image,
        &.video {
            .text {
                margin-bottom: 20px;
            }
        }

        .videoWrapper {
            height: 0;
            margin: 0 0 15px 0;
            padding-bottom: 56.25%;
            padding-top: 0;
            position: relative;

            iframe {
                border: medium none;
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
            }
        }

        .text {
            position: relative;
            //overflow: auto;

            iframe {
                max-width: 100%;
            }

            .action {
                a {
                    @include cabin17(400, $color2);
                    display: inline-block;
                    padding: 10px;
                    border-radius: 2px;
                    border: 2px solid $color2;
                    background-color: $white;
                    text-transform: uppercase;
                    transition: all 0.3s;
                    text-decoration: none;

                    &:hover {
                        color: $white;
                        background-color: $color2;
                    }
                }
            }

            table {
                width: 100%;
                border-collapse: collapse;
                max-width: 100%;
                table-layout: fixed;

                tr,
                td,
                th {
                    border-collapse: collapse;
                }

                thead {
                    @include mobile {
                        display: none;
                    }

                    tr {

                    }

                    th {
                        padding: 10px;
                        @include mobile {
                            display: table-row;
                            float: left;
                            width: 100%;
                            padding: 5px;
                        }
                    }
                }

                tbody {
                    tr {
                        td {
                            @include cabin17(700, $color1);
                            padding: 20px;
                            background-color: $grey;
                            border: 2px solid $white;

                            &:first-child {
                                color: $color2;
                            }
                        }
                    }

                    tr td {
                        @include mobile {
                            display: table-row;
                            float: left;
                            width: 100%;
                            padding: 10px;
                            text-align: left;
                            &:before {
                                content: attr(data-th);
                                display: block;
                                text-decoration: underline;
                                margin-bottom: 5px;
                            }
                        }
                    }
                }
            }
        }

        &.galerie {
            .wrap {
                overflow: hidden;
            }

            .galerie-image {
                float: left;
                width: 70%;
                @include mobile {
                    float: none;
                    width: 100%;
                }
            }

            .galerie-caption {
                float: left;
                width: 30%;
                @include mobile {
                    float: none;
                    width: 100%;
                }

                ul {
                    li {
                        @include cabin17(400, $color1);
                        background-image: none;
                        margin: 20px 0;

                        &.active {
                            &:before {
                                border-left-color: $color2;
                            }
                        }

                        span {
                            font-weight: 700;
                        }
                    }
                }
            }

            .slider {
                position: relative;
                margin: 0 10px 15px 10px;
                overflow: hidden;

                .frame {
                    width: 100%;
                    padding: 0;
                }

                .frame .slidee {
                    margin: 0;
                    padding: 0;
                    height: 100%;
                    list-style: none;
                    overflow: hidden;
                    position: relative;
                }

                .frame .slidee li {
                    float: left;
                    margin: 0;
                    padding: 0;
                    height: 100%;
                    position: relative;

                    &:before {
                        display: none;
                    }

                    img {
                        max-width: 100%;
                        height: auto;
                        vertical-align: middle;
                    }

                    a {
                        display: block;
                        position: relative;
                    }
                }

                .pages {
                    transition: all, 0.3s;
                    position: absolute;
                    right: 5px;
                    bottom: 5px;
                    left: 5px;
                    list-style: none;
                    margin: 0;
                    text-align: center;
                    padding: 0;
                    width: 100%;
                }

                .pages li {
                    display: inline-block;
                    width: 18px;
                    height: 18px;
                    border: 4px solid $white;
                    margin: 0 3px;
                    text-indent: -999px;
                    border-radius: 10px;
                    cursor: pointer;
                    overflow: hidden;
                    background: $white;
                    text-align: left;
                    padding: 0;
                    box-shadow: 0 0 4px 1px rgba($black, 0.2);

                    &:before {
                        display: none;
                    }
                }

                .pages li:hover {
                    background: lighten($color2, 10%);
                }

                .pages li.active {
                    background: $color2;
                }

                .prev,
                .next {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    width: 60px;

                    a {
                        transition: all, 0.3s;
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        background-repeat: no-repeat;
                        background-position: center center;
                        display: block;
                        background-color: rgba($white, 0.5);

                        &:hover,
                        &:focus {
                            background-color: rgba($white, 1);
                        }

                        &.disabled {
                            display: none;
                        }
                    }
                }

                .prev {
                    left: 0;

                    a {
                        background-image: url('../img/slider/prev.png');

                        &.disabled {
                            left: -60px;
                        }
                    }
                }

                .next {
                    right: 0;

                    a {
                        background-image: url('../img/slider/next.png');

                        &.disabled {
                            right: -60px;
                        }
                    }
                }
            }
        }
    }
}
#category-content {
    hr {
        border: none;
        border-top: 1px solid $color1;
        margin: 20px 0;
    }
    .category-name {
        margin: 0 0 10px 0;
        h1,
        .h1 {
            @include yanone40(400, $color1);
            text-transform: uppercase;
            margin: 0 0 20px 0;
        }
    }
    .category-description {
        @include cabin17(400, $color1);
        margin: 0 0 20px 0;
        a {
            color: $color2;
            text-decoration: none;
            &:hover{
                text-decoration: underline;
            }
        }
        &.bottom {
            padding: 0 0 30px 0;
        }
        h1,h2,.h1,.h2 {
            @include yanone22(700, $color2);
            text-transform: uppercase;
        }
        h2,
        .h2 {
            color: $color2;
        }
        h3,h4,h5,h6,.h3,.h4,.h5,.h6 {
            @include yanone18(700, $color2);
            text-transform: uppercase;
        }
        h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6, p, ul, ol {
            margin: 10px 0;
            &:first-child {
                margin-top: 0;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }

        h3,
        .h3 {
            @include font-size(22.4);
            line-height: 1;
        }

        h4,
        .h4 {
            @include font-size(20.8);
            line-height: 1;
        }
    }

    h2.dotdotdot,
    .h2.dotdotdot {
        @include yanone(400);
        @include dotdotdot(20,22,2);
        overflow: hidden;
        margin: 0;
        padding: 0;
        text-transform: uppercase;
        color: $color2;
    }
    #category-result {
        margin: 0 0 30px 0;
        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            li {
                margin: 20px 0 0 0;
                padding: 0;
                width: 33.333%;
                float: left;
                a {
                    color: $color2;
                    text-decoration: none;
                    display: block;

                    .inner {
                        .date {
                            @include yanone(700);
                            color: $white;
                            @include font-size-lh(25, 36);
                            position: relative;
                            display: inline-block;
                            height: 36px;
                            margin: 0 0 25px 0;
                            padding: 0 65px 0 10px;
                            background-color: $color1;

                            &:after {
                                content: '';
                                position: absolute;
                                top: 0;
                                right: 0;
                                @include triangle(10, 36, 'left', $white);
                            }
                        }
                        h3,
                        .h3 {
                            @include yanone(700);
                            @include dotdotdot(22.4, 22.4, 2);
                            margin: 0;
                            padding: 0;
                            color: $color1;
                            text-transform: uppercase;
                        }

                        h4,
                        .h4 {
                            @include yanone(400);
                            color: $color1;
                            @include font-size(20.8);
                            line-height: 1;
                            margin: 0 0 25px 0;
                            padding: 0;
                            text-transform: uppercase;
                        }
                        .img {
                            margin-top: 10px;
                            width: 100%;
                            height: 165px;
                            border-bottom: 4px solid $color2;
                            img {
                                height: 100%;
                                object-fit: cover;
                                object-position: 50% 50%;
                            }
                        }
                        img {
                            width: 100%;
                            height: auto;
                            border-bottom: 4px solid $color2;
                        }
                        .content {
                            @include cabin16(400, $color1);
                            margin: 15px 0;
                            padding: 0;
                            .extrait {
                                @include cabin(400);
                                @include dotdotdot(15,18,3);
                                color: $color1;
                            }
                            .read {
                                padding: 15px 0;
                                span {
                                    display: inline-block;
                                    @include cabin15(400, $color2);
                                    padding: 10px;
                                    border-radius: 2px;
                                    border: 2px solid $color2;
                                    background-color: $white;
                                    text-transform: uppercase;
                                    @include transition(all, 0.3s);
                                    text-decoration: none;
                                }
                            }
                        }
                    }
                    &:hover {
                        .inner {
                            .content {
                                .read {
                                    span {
                                        color: $white;
                                        background-color: $color2;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .grid {
            position: relative;
            margin: 0 -20px;
            @include mobile {
                margin: 0;
            }
            @include tablet {
                margin: 0;
            }
            .grid-item {
                float: left;
                width: 25%;
                padding: 0 20px;
                @include small-desktop() {
                    width: 50%;
                }
                @include mobile(null,599px) {
                    width: 100%;
                }
            }
        }
    }
}

@media print {
    * {
        background: transparent !important;
        //color: #000 !important;
        box-shadow: none !important;
        text-shadow: none !important;
    }
    a,
    a:visited {
        text-decoration: underline;
    }
    a[href]:after {
        content: " (" attr(href) ")";
        @media print {
            display: none;
        }
    }
    abbr[title]:after {
        content: " (" attr(title) ")";
    }
    .ir a:after,
    a[href^="javascript:"]:after,
    a[href^="#"]:after {
        content: "";
    }
    pre, blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }
    thead {
        display: table-header-group;
    }
    tr, img {
        page-break-inside: avoid;
    }
    img {
        max-width: 100% !important;
    }
    iframe {
        display: none;
    }
    @page {
        size: auto;   /* auto is the initial value */
        margin: 0 0.5cm;  /* this affects the margin in the printer settings */
    }
    p,
    h2,
    h3,
    .h2,
    .h3 {
        orphans: 3;
        widows: 3;
    }
    h2,
    h3,
    .h2,
    .h3 {
        page-break-after: avoid;
    }
}

#map {
    width: 100%;
    height: 620px;
    overflow: hidden;
}

.map-legend {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    border-bottom: 1px solid $grey;

    p {
        @include cabin14(700, $color1);
        display: flex;
        align-items: center;
        margin: 15px 0 !important;
        padding-right: 10px;
    }

    img {
        margin: 0 5px 0 0;
        max-width: 40px;
    }
}
.information__watermark {
    display: none;
}
.layout-texte {
    .css-7l897f {
        background-color: $color1;
    }
}