// Blocs, Cols, Rows
$bpColFw: 1023px;
$bpColHalf: 1279px;
$gutter: 30px;
$gutterhalf: $gutter / 2;
#flex-content {
    .bloc-wrapper {
        margin: 25px 0;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
    @include mq(null,$bpColFw){
        max-width: 520px;
        margin: auto;
        .bloc {
            flex-direction: column;
            &.reverse {
                flex-direction: column-reverse;
            }
        }
        .col {
            //width: calc(100% - #{$gutter}) !important;
            width: 100% !important;
        }
    }
    @include mq($bpColFw + 1,$bpColHalf){
        .cols-wrapper-2,
        .cols-wrapper-4 {
            .col {
                width: calc(50% - #{$gutter}) !important;
            }
        }
    }
    @include mq($bpColFw + 1,null){
        .cols-wrapper-1 {
            margin-bottom: 0;
            .col {
                width: calc(100% - #{$gutter});
            }
        }
        .cols-wrapper-2 {
            .col {
                width: calc(50% - #{$gutter});
            }
            @for $i from 1 through 10 {
                $base: $i * 10 ;
                $c1: (100 - $base);
                $c2: (0 + $base);
                &.cols-#{$c1}-#{$c2} {
                    .col-1 {
                        $c1Val: unquote($c1 +'%');
                        width: calc(#{$c1Val} - #{$gutter});

                    }
                    .col-2 {
                        $c2Val: unquote($c2 +'%');
                        width: calc(#{$c2Val} - #{$gutter});
                    }
                }
            }
            $m13: 100% / 3;
            $m23: $m13 * 2;
            &.cols-20-80 {
                .col-1 {
                    width: calc(#{$m13} - #{$gutter});
                }
                .col-2 {
                    width: calc(#{$m23} - #{$gutter});
                }
            }
            &.cols-80-20 {
                .col-1 {
                    width: calc(#{$m23} - #{$gutter});
                }
                .col-2 {
                    width: calc(#{$m13} - #{$gutter});
                }
            }
            &.cols-33-67 {
                .col-1 {
                    width: calc(33% - #{$gutter});
                }
                .col-2 {
                    width: calc(67% - #{$gutter});
                }
            }
            &.cols-67-33 {
                .col-1 {
                    width: calc(67% - #{$gutter});
                }
                .col-2 {
                    width: calc(33% - #{$gutter});
                }
            }
        }
        .cols-wrapper-3 {
            .col {
                width: calc(33.333% - #{$gutter});
            }
        }
        .cols-wrapper-4 {
            .col {
                width: calc(25% - #{$gutter});
            }
        }
        #carte-subventions {
            .cols-wrapper-2 {
                &.cols-40-60 {
                    .col-1 {
                        padding-right: 20px;
                    }
                    .col-2 {
                        padding-left: 20px;
                    }
                }
            }
        }
    }
    @include transition(all,0.3s);
    &.archives {
        a {
            display: block;
            text-decoration: none;
            height: 100%;
        }
        .inner {
            .title {
                margin-bottom: 15px;
            }
            .img {
                margin-bottom: 15px;
            }
            .txt {
                max-height: 100px;
                overflow: hidden;
            }
        }
    }
    .post-title,
    .post-content {
        margin-bottom: #{$gutter};
    }
    .bloc {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-left: -#{$gutter};
        margin-bottom: 0;
        &:after {
            content: "";
            flex: auto;
        }
        .intro {
            width: calc(100% - #{$gutter});
            margin-bottom: #{$gutter};
            //margin-left: #{$gutter};
            margin-left: 10px;
            margin-right: 10px;
        }
        .col {
            //margin-left: #{$gutter};
            margin-left: 10px;
            margin-right: 10px;
            margin-bottom: #{$gutter};
            @include transition(all,0.3s);
            .row {
                margin-bottom: #{$gutterhalf};
                &:last-child {
                    margin: 0;
                }
            }
        }
    }
}
// Layouts
#flex-content {
    a {
        color: $black;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
    ol {
        padding: 0 0 0 20px;
        li {
            position: relative;
            padding: 0;
            @include font-size-lh(16,20);
            margin-bottom: 5px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .layout-texte ul {
        padding: 0;
        margin: 0;
        list-style: none;
        li {
            position: relative;
            padding: 0 0 0 20px;
            @include font-size-lh(16,20);
            margin-bottom: 5px;
            //&:before {
            //    @include position(absolute,5px,null,null,0);
            //    content: "";
            //    width: 10px;
            //    height: 10px;
            //    background-color: $black;
            //}
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .video-wrapper {
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
        iframe {
            @include position(absolute,0,null,null,0);
            width: 100%;
            height: 100%;
            border: none;
        }
    }
    .layout-texte {
        blockquote {
            p {
                color: $black;
                @include font-size-lh(24,30);
            }
            &:before,
            &:after {
                color: $black;
                margin: 0;
                @include font-size-lh(28,30);
            }
        }
    }
    .layout-image {}
    .layout-image-texte {
        .lit {
            position: relative;
            @include tablet-up() {
                padding-bottom: 100px;
            }
            &--image {
                position: relative;
            }
            &--txt {
                max-width: 100%;
                background-color: $white;
                padding: 25px 25px 0 25px;
                @include tablet-up() {
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    right: 0;
                    transform: translateX(-50%);
                    width: 1000px;
                    padding: 30px 60px 5px 60px;
                }
                h1,
                .h1 {
                    @include yanone70(700, $color1);
                    text-transform: initial;
                    @include tablet-up() {
                        text-align: center;
                    }
                    @include tablet() {
                        @include yanone30(700, $color1);
                    }
                    b,
                    strong {
                        color: $color2;
                    }
                }
                h2,
                .h2 {
                    @include yanone45(400, $color2);
                    text-transform: initial;
                    @include tablet-up() {
                        text-align: center;
                    }
                    @include tablet() {
                        @include yanone20(700, $color1);
                    }
                }
            }
        }
    }
    #intro {
        .layout-image-texte {
            .lit--txt {
                @include tablet-up() {
                    text-align: center;
                }
            }
        }
    }
    .layout-facture-ams {
        .lfa {
            display: flex;
            flex-wrap: wrap;
            &--image {
                width: 50%;
                @include tablet() {
                    width: 100%;
                }
                img {
                    @include object();
                }
            }
            &--content {
                width: 50%;
                background-color: $bg-reviews;
                padding: 50px 75px;
                position: relative;
                @include tablet() {
                    width: 100%;
                    background-color: $white;
                    padding: 25px 25px 0 25px;
                }
                @include tablet-up() {
                    &:before {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: -18px;
                        width: 0;
                        height: 0;
                        @include triangle(18, 25, left, $bg-reviews);
                        transform: translateY(-50%);
                        @include tablet() {
                            top: -18px;
                            left: 50%;
                            @include triangle(18, 25, top, $bg-reviews);
                            transform: translateX(-50%);
                        }
                    }
                }
            }
            &--txt {
                max-width: 590px;
                h2,
                .h2 {
                    @include yanone30(700, $color2);
                    text-transform: initial;
                    @include tablet() {
                        @include yanone20(700, $color1);
                    }
                }
            }
        }
    }
    .layout-ancres {
        .la {
            &--items {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
            }
            &--item {
                padding: 15px 20px;
                position: relative;
                border-bottom: 1px solid $grey;
                transition: all 0.3s;
                @include tablet() {
                    width: 100%;
                }
                &:before {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    background-color: $grey;
                    width: 100%;
                    height: 1px;
                }
                @include tablet-up() {
                    &:not(:last-child) {
                        &:after {
                            content: '';
                            position: absolute;
                            top: 0;
                            right: 0;
                            background-color: $grey;
                            width: 1px;
                            height: 100%;
                        }
                    }
                }
                @include tablet() {
                    &:first-child {
                        &:after {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 0;
                            background-color: $grey;
                            width: 100%;
                            height: 1px;
                        }
                    }
                }
                &:hover {
                    &:before {
                        background-color: $color2;
                        height: 2px;
                    }
                    .la--item--link {
                        .picto-arrow-nav {
                            fill: $color2;
                            transform: rotate(270deg);
                        }
                    }
                    .la--item--txt {
                        color: $color2;
                    }
                }
                &--link {
                    text-decoration: none;
                    display: flex;
                    align-items: center;

                    .picto-arrow-nav {
                        fill: $color1;
                        flex: 0 0 25px;
                        padding-left: 15px;
                        transform: rotate(180deg);
                        transition: all 0.3s;
                    }
                }
                &--txt {
                    @include yanone20(700, $color1);
                    text-transform: uppercase;
                    flex: 0 1 100%;
                    margin: 0;
                }
            }
        }
    }
    .layout-video {
        .wrap {
            max-width: 1100px;
            margin: 0 auto;
        }
    }
    .layout-galerie {
        .wrap {
            position: relative;
        }
        .slider {
            white-space: nowrap;
            font-size: 0;
            overflow: hidden;
            margin: 0 auto;
        }
        .arrows-container {
            @include position(absolute,0,null,null,0);
            width: 100%;
        }
        button.slick-arrow {
            position: absolute;
            bottom: 10px;
            width: 40px;
            height: 40px;
            padding: 0;
            margin: 0;
            background-color: $white;
            border: 1px solid $white;
            text-align: center;
            color: $black;
            @include font-size-lh(30,38);
            @include transition(all,0.3s);
            z-index: 2;
            &:hover {
                background-color: $black;
                color: $white;
            }
            &.slick-prev {
                left: 10px;
            }
            &.slick-next {
                right: 10px;
            }
        }
        .slide {
            position: relative;
            white-space: normal;
            display: inline-block;
            vertical-align: middle;
            width: 100%;
            a {
                display: block;
                &:hover {
                    .caption {
                        max-height: inherit;
                    }
                }
            }
            .inner {
                position: relative;
            }
            .spacer {
                .img {
                    @include background-size(cover);
                    background-position: center center;
                    background-repeat: no-repeat;
                    img {
                        &[data-img="image"]{
                            display: none;
                        }
                        &[data-img="sizer"]{
                            visibility: hidden;
                        }
                    }
                }
            }
            .caption {
                @include position(absolute,null,null,0,0);
                @include transform(translateY(100%));
                width: 100%;
                background: $black;
                padding: 10px 60px;
                min-height: 60px;
                color: $white;
                max-height: 80px;
                @include transition(all,0.3s);
                @include mq(null,$bpColFw){
                    display: none;
                }
                .text-1 {
                    @include font-size-lh(18,20);
                    text-transform: uppercase;
                }
                .text-2 {
                    @include font-size-lh(14,20);
                }
            }
        }
        .slick-current {
            .slide {
                .caption {
                    @include transform(translateY(0));
                }
            }
        }
    }
    .layout-bouton {
        .bouton-wrap {
            text-align: left;
            &.ta-left {
                text-align: left;
            }
            &.ta-center {
                text-align: center;
            }
            &.ta-right {
                text-align: right;
            }
            //&.inline_block {
            //    a {
            //        display: inline-block;
            //    }
            //}
            &.block {
                a {
                    display: block;
                }
            }
            span {
                display: inline-block;
                //&.txt {
                //    @include font-size-lh(14,20);
                //}
                &.picto {
                    margin-left: 10px;
                    //@include font-size-lh(18,20);
                    @include tablet {
                        display: none;
                    }
                }
            }
            a {
                @include cabin20(400, $white);
                position: relative;
                padding: 0 15px;
                border: 2px solid $color2;
                background-color: $color2;
                text-decoration: none;
                min-width: 250px;
                min-height: 53px;
                border-radius: 53px;
                vertical-align: middle;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                transition: all 0.3s;
            }
        }
    }
    .layout-map {
        .map-wrapper {
            position: relative;
            padding-bottom: 55%;
            @include mq(null,$bpColFw){
                padding-bottom: 90%;
            }
            .map-container {
                @include position(absolute,0,null,null,0);
                width: 100%;
                height: 100%;
            }
            .infowindowcontent {
                @include font-size(14);
                line-height: 1.35;
            }
        }
    }
}