.header {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1001;

    .body--product--configurator &,
    body.product & {
        @media print {
            display: none;
        }
    }

    #layer_cart,
    .layer_cart_overlay {
        display: none !important;
    }

    &--banner {
        background-color: $color1;

        &--inner {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 37px;
            //background-color: $color2;
            background-color: #eb6f0d;
        }

        &--logo {
            width: 252px;
            height: 37px;
            @include tablet {
                display: none;
            }

            svg {
                width: 100%;
                height: 100%;
            }
        }

        &--content {
            width: calc(100% - 255px);
            @include tablet {
                width: 100%;
            }

            .st_image_layered_description_inner p {
                padding: 0;
            }
        }
    }

    &--top {
        position: relative;
        z-index: 1;
        background-color: $color1;

        &--inner {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 100px;
            @include tablet() {
                position: relative;
                height: 70px;
                padding-left: 15px;
            }
        }
    }

    &--logo {
        width: 100%;
        max-width: 0;
        transition: all 0.3s;
        @include tablet() {
            max-width: 300px;
        }
        @include mobile() {
            max-width: 180px;
        }

        svg {
            width: 100%;
        }
    }

    &--menu {
        width: 100%;
        height: 100%;
        @include small-desktop($w1024) {
            width: auto;
        }
        @include mobile() {
            width: auto;
        }

        &--nav {
            display: flex;
            align-items: center;
            height: 100%;
            margin: 0;
            padding: 0;
            list-style: none;
        }

        &--link {
            text-decoration: none;
            display: flex;
            height: 100%;
        }

        &--item {
            height: 100%;
            margin: 0 25px;

            &--inner {
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;

                &:after {
                    content: '';
                    position: absolute;
                    bottom: 30px;
                    left: 0;
                    width: 0;
                    height: 3px;
                    background-color: $white;
                    border-radius: 3px;
                    transition: all 0.3s;
                }

                &.active,
                &:hover {
                    &:after {
                        width: 100%;
                    }
                }
            }
        }

        &--picto {
            max-width: 66px;
        }

        &--txt {
            @include yanone22(400, $white);
            margin: 0 0 0 7px;
        }
    }

    &--submenu {
        position: absolute;
        top: 100px;
        left: 0;
        width: 100%;
        z-index: 15;
        background-color: $color1;
        padding-top: 45px;
        padding-bottom: 30px;
        transition: all 0.3s;
        opacity: 0;
        visibility: hidden;

        &.active {
            opacity: 1;
            visibility: visible;
        }

        &--inner {
            display: flex;
            justify-content: space-between;
            width: $w1150;
            max-width: 100%;
            margin: 0 auto;
        }

        &--product {
            width: 334px;
            margin-top: 13px;

            &--link {
                text-decoration: none;
            }

            &--image {
                width: 100%;
                height: 175px;
                background-color: $white;

                img {
                    @include object();
                }
            }

            &--name {
                @include yanone20(700, $white);
                text-align: center;
                text-transform: uppercase;
                margin-top: 5px;
            }
        }

        &--nav {
            display: flex;
            width: 720px;
            margin: 0;
            padding: 0;
            flex-wrap: wrap;
            justify-content: space-between;
            list-style: none;
        }

        &--item {
            width: 313px;
            border-bottom: 1px solid $white;
        }

        &--link {
            text-decoration: none;
        }

        &--txt {
            @include yanone22(400, $white);
            margin: 0;
            padding: 13px 0;
        }
    }

    &--bottom {
        background-color: $white;
        box-shadow: 0 3px 3px rgba($black, 0.1);

        &--inner {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            height: 70px;
            @include small-desktop() {
                padding: 0 15px;
            }
            @include mobile() {
                justify-content: space-between;
            }
        }
    }

    &--account,
    &--cart {
        @include mobile() {
            display: none;
        }
    }

    &--account,
    &--cart,
    &--contact,
    &--pro,
    &--trial,
    &--reprise,
    &--tel {
        flex: 0 0 auto;
        margin-left: 30px;
        @include mq(null, 1200px) {
            margin-left: 20px;
            .header--actions & {
                margin-left: 0;
            }
        }
        #adtm_menu_inner & {
            display: none;
            @include tablet() {
                display: block;
            }
        }

        &--link {
            display: flex;
            align-items: center;
            text-decoration: none;
            @include mobile() {
                flex-direction: column;
            }

            > div {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .adtm_menu_container & {
                    @include tablet() {
                        flex-direction: row;
                    }
                }
            }
        }

        svg {
            width: 32px;
            height: 32px;
            @include tablet() {
                .header--actions & {
                    width: 24px;

                    .cls-1 {
                        fill: $white;
                    }
                }
            }
        }

        .adtm_menu_container & {
            p {
                @include yanone22(400, $white);
                margin: 0 0 0 10px;
            }
        }
    }

    &--contact {
        position: relative;
        @include mobile() {
            order: 4;
            margin-left: 0;
        }

        p {
            @include yanone15(400, $color2);
            margin: 5px 0 0 0;
            &.d {
                display: none;
                @include mobile-up() {
                    display: block;
                }
            }
            &.m {
                @include mobile-up() {
                    display: none;
                }
            }
        }
    }

    &--cart {
        position: relative;

        &--link {
            text-decoration: none;
        }

        p {
            @include mobile-up() {
                @include yanone15(400, $color2);
                margin: 5px 0 0 0;
                display: block;
            }
        }

        &--nb {
            @include cabin(400);
            @include font-size-lh(11, 11);
            color: $white;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $color2;
            border-radius: 50%;
            aspect-ratio: 1;
            min-width: 20px;
            position: absolute;
            top: -5px;
            right: 0;
            @include tablet() {
                right: -10px;
                .header--actions & {
                    right: initial;
                    left: -10px;
                }
            }
        }
    }

    &--search {
        flex: 0 1 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        @include tablet() {
            display: none;
        }
        #adtm_menu_inner & {
            display: none;
            @include tablet() {
                display: block;
            }
        }

        &--form {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            height: 32px;
            padding: 0 11px;
            border: 2px solid $color2;
            border-radius: 32px;

            .adtm_menu_container & {
                width: 100%;
                height: 45px;
                border-color: $white;
                margin: 25px 0;
                @include tablet-up() {
                    display: none;
                }
            }

            &--input {
                max-width: 100%;
                width: 300px;
                height: 75%;
                border: none;
                @include cabin16(400, $color2);
                font-style: italic;
                @include mq(1281px, 1450px) {
                    width: 140px;
                }
                @include mq(null, 1280px) {
                    width: 100px;
                }
                @include mobile {
                    width: 200px;
                }

                .adtm_menu_container & {
                    color: $white;
                    background-color: transparent;
                }

                &::placeholder {
                    @include cabin16(400, rgba($color2, 0.5));
                    font-style: italic;

                    .adtm_menu_container & {
                        color: $white;
                    }
                }
            }

            &--button {
                background-color: transparent;
                border: none;
                cursor: pointer;

                svg {
                    width: 16px;

                    .adtm_menu_container & {
                        .cls-1 {
                            fill: $white;
                        }
                    }
                }
            }
        }
    }

    &--tel {
        margin-left: 0;
        @include mq(null, 1200px) {
            .header--actions & {
                margin-left: 0;
            }
        }
        @include mobile() {
            order: 3;
        }
        a {
            color: $color2;
            text-decoration: none;
        }
        p {
            @include yanone25(700, $color2);
            display: block;
            margin: 0;
            @include mq(null, 1200px) {
                @include yanone18(700, $color2);
            }
            &.d {
                @include tablet() {
                    display: none;
                }
            }
            &.m {
                @include mobile-up() {
                    display: none;
                }
            }
        }
        .fa-phone-alt {
            transform: scale(2.13);
            @include tablet-up() {
                display: none;
            }
            path {
                fill: $color2;
            }
        }
    }

    &--account {
        p {
            @include mobile-up() {
                @include yanone15(400, $color2);
                margin: 5px 0 0 0;
                display: block;
            }
        }
    }

    &--aside {
        position: fixed;
        bottom: get-vh(150);
        right: get-vw(75);
        z-index: 55;

        .body--product--configurator &,
        body.product & {
            @media print {
                display: none;
            }
        }

        &--cta {
            margin-bottom: 15px;
            &.cta--vae {
                svg {
                    width: 35px;
                }
            }
            @include mobile() {
                margin-bottom: 0;
            }
        }
    }

    &--actions {
        display: flex;
        justify-content: space-between;
        margin-top: 25px;
        @include tablet {
            margin-top: 0;
        }
    }

    &--pro {
        margin-left: 0;
        @include mobile() {
            flex: 0 1 100%;
        }

        p {
            @include yanone25(700, $color2);
            display: block;
            margin: 0;
            @include mq(null, 1200px) {
                @include yanone18(700, $color2);
            }
        }
    }
    &--reprise {
        @include mobile() {
            order: 1;
            margin-left: 0;
        }

        p {
            @include yanone25(700, $color2);
            line-height: inherit;
            display: block;
            margin: 0;
            &.d {
                @include mobile() {
                    display: none;
                }
            }
            &.m {
                @include mobile-up() {
                    display: none;
                }
            }
            @include mq(null, 1200px) {
                @include yanone15(700, $color2);
                line-height: inherit;
            }
            span {
                @include yanone15(400, $color2);
                line-height: inherit;
                @include mq(null, 1200px) {
                    @include yanone12(400, $color2);
                    line-height: inherit;
                }
            }
        }
        img {
            @include mobile-up {
                margin-right: 10px;
            }
        }
    }
    &--trial {
        margin-left: 50px;
        @include mq(null, 1200px) {
            .header--actions & {
                margin-left: 0;
            }
        }
        @include mobile() {
            order: 2;
            margin-left: 0;
        }
        p {
            @include yanone25(700, $color2);
            line-height: inherit;
            margin: 0;
            @include mq(null, 1200px) {
                @include yanone15(700, $color2);
                line-height: inherit;
            }
            &.d {
                @include mobile() {
                    display: none;
                }
            }
            &.m {
                @include mobile-up() {
                    display: none;
                }
            }
            span {
                @include yanone15(400, $color2);
                line-height: inherit;
                @include mq(null, 1200px) {
                    @include yanone12(400, $color2);
                    line-height: inherit;
                }
            }
        }
        svg {
            @include mobile-up {
                margin-right: 10px;
            }
            .cls-1 {
                fill: $color2;
            }
        }
    }
}
.st_banner_block .adveditor_image[src=""],
.st_banner_block .adveditor_image[data-src=""] {
    display: none;
}
.st_image_layered_description_inner p {
    margin: 0;
    padding: 10px 0;
}
.st_banner_image {
    height: 100%;
}