.push--socialwall {
    margin-top: 100px;
    @include tablet() {
        margin-top: 65px;
    }

    &--header {
        text-align: center;
        width: 100%;
        max-width: 485px;
        margin: 0 auto;
        @include tablet() {
            padding: 0 15px;
        }

        &--1 {
            @include yanone70(700, $color1);
            @include tablet() {
                @include yanone55(700, $color1);
            }
        }

        &--2 {
            @include cabin15(400, $color1);
            margin-top: 35px;
        }
    }

    &--items {
        display: grid;
        grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        margin-top: 20px;
        @include tablet() {
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: 1fr;
        }
    }

    &--item {
        margin: 1px;
        @include tablet-up() {
            &:first-child {
                grid-column: 1 / 2;
                grid-row: 1 / 3;
            }
        }

        &--inner {
            position: relative;
        }

        &--image {
            background-color: $grey;
            width: 100%;
            aspect-ratio: 1;

            img {
                @include object();
            }
        }

        &--picto {
            position: absolute;
            top: 14px;
            right: 16px;

            svg {
                transform: scale(1.5);
                transition: all 0.3s;
            }
        }
    }
}