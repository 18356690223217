@import "partials/mixins";
@import "partials/variables";
@import "partials/typography";
@import "partials/functions";
@import "partials/flex-content";
@import "plugins/advancedtopmenu";
@import "plugins/fancybox";
@import "plugins/jquery-footable";
@import "plugins/colissimo";
//@import "plugins/tarteaucitron";
/* ----------------------------- */
@import "fontawesome/fontawesome";
//@import "fontawesome/solid";
//@import "fontawesome/regular";
//@import "fontawesome/brands";
@import "fontawesome/light";
//@import "fontawesome/duotone";
/* ----------------------------- */
@import "components/header";
@import "components/footer";
@import "components/featured-products";
@import "components/product";
@import "components/category";
@import "components/pagination";
@import "components/push";
@import "components/cta";
@import "components/contact";
@import "components/account";
@import "components/checkout";
@import "components/breadcrumb";
@import "components/notifications";
@import "components/modal";
@import "components/form-fields";
@import "components/psgdpr";
@import "components/cms";
@import "components/404";
@import "components/search";
@import "components/wordpress";
@import "components/my-amsterdamer";
@import "components/rgpd";
@import "components/realisations";
@import "components/popup";

:root {
    --vh: 100vh;
    --banner-height: 0;
}

*,
*:after,
*:before {
    @include box-sizing(border-box);
}

.visuallyhidden {
    @include visuallyhidden;
}

.clearfix {
    @include clearfix;
}

body {
    @include cabin15(400, $color1);
    margin: 0;

    &.menu-open {
        overflow: hidden;
    }
}

img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
}

.visuallyhidden,
.visually-hidden {
    @include visuallyhidden;
}

.container {
    width: $w1470;
    max-width: 100%;
    margin: 0 auto;
    @include tablet-up() {
        padding: 0 15px;
    }
    .category--heading &,
    .footer & {
        position: relative;
    }
    @include tablet() {
        .cms &,
        .pagenotfound & {
            padding: 0 15px;
        }
    }
}

.main {
    position: relative;

    .body--product--configurator &,
    body.product & {
        @media print {
            margin-top: 0;
        }
    }
}

.page--top {
    text-align: center;
    position: absolute;
    right: 10%;
    bottom: 0;
    z-index: 1;
    width: 82px;
    cursor: pointer;
    @include small-desktop() {
        display: none;
    }

    .product--resume & {
        right: initial;
        bottom: -100px;
        left: 50%;
        transform: translateX(-50%);
    }

    &--picto {
        transition: all 0.3s;

        .page--top:hover & {
            transform: translateY(-10px);

            .picto-page-top {
                .cls-1 {
                    stroke: $color2;
                    stroke-width: 4px;
                }
            }
        }
    }

    &--title {
        @include cabin15(400, $color1);
    }
}

.unvisible {
    display: none;
}

.alert-danger {
    border: 2px dashed $error;
    margin: 20px 0;
    padding: 20px;

    p {
        color: $error;
    }

    ol {
        margin: 0;
        padding: 0 0 0 15px;

        li {
            @include cabin17(400, $error);
        }
    }
}

.return-to-search {
    padding-bottom: 15px;
    a {
        text-decoration: none;
        display: inline-flex;
        align-items: center;
        p {
            color: $color2;
            text-decoration: underline;
        }
        &:hover {
            .picto-back {
                transform: translateX(-10px);
            }
        }
    }
    .picto-back {
        margin-right: 10px;
        transition: all 0.3s;
        .cls-1 {
            fill: $color1;
        }
    }

}

.crisp-client {
    position: relative;
    z-index: 2000;
    span[data-id] {
        display: none !important;
    }
    a[role="button"] {
        display: none !important;
    }
    #crisp-chatbox.show {
        display: block !important;
        > div {
            display: block !important;
        }
    }
    #crisp-chatbox {
        > div {
            > a {
                display: none !important;
                opacity: 0;
            }
        }
    }
    .cc-kv6t {
        .cc-7doi {
            display: none !important;
            opacity: 0;
        }
        .cc-1ada,
        .cc-1aev,
        .cc-2hzo:hover,
        .cc-18sf:before,
        .cc-18sf:after {
            background-color: $color1 !important;
        }
        .cc-ew5j:before,
        .cc-ew5j:after {
            background-color: $white !important;
        }
        .cc-1xry .cc-ge4v .cc-qqzz {
            @include mobile() {
                height: calc(100% - 100px) !important;
            }
        }
        .cc-1xry .cc-unoo .cc-7doi {
            display: none !important;
            opacity: 0;
        }
    }
    .cc-1xry .cc-ge4v .cc-qqzz .cc-5b24 .cc-g7iw .cc-1uz4 .cc-pjpe::placeholder {
        color: $color2;
    }
    .cc-16jc {
        background-color: $color2;
    }
    .cc-g73w:hover .cc-199t {
        background-color: $color2;
    }
}

.ac_results {
    background-color: $white;
    top: 190px !important;
    left: 0 !important;
    box-shadow: 0 10px 5px -5px rgba($black, 0.3);;
    z-index: 1000;
    @include mobile() {
        height: 0;
        overflow: hidden;
    }

    ul {
        display: flex;
        padding: 20px 15px;
        max-width: $w1470;
        margin: 0 auto;

        li {
            text-align: center;
            text-transform: uppercase;
            list-style: none;
            width: calc(100% / 6);
            padding: 6px;
            cursor: pointer;
            transition: all 0.3s;
            .pimg {
                margin-bottom: 10px;
                border-bottom: 5px solid $color2;
                img {
                    display: block;
                    width: 100%;
                    height: auto;
                }
            }
            .pname {
                @include cabin12(400, $color1);
                strong {
                    @include cabin(700)
                }
            }
        }
    }
}

.st_banner_block {
    picture {
        display: flex;
        justify-content: center;
    }
    .st_banner_text {
        a {
            @include mobile {
                position: relative;
                width: 100%;
                height: 37px;
                display: block;
                overflow: hidden;
            }
        }
        img {
            @include mobile() {
                @include object('contain');
                position: absolute;
                top: 0;
                left: 0;
                transform: scale(1.2);
            }
        }
    }
}

#___ratingbadge_0 {
    display: none !important;
}