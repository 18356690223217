.push--subsidiesmap {
    margin-top: 120px;
    @include tablet() {
        margin-top: 60px;
    }

    &--link {
        text-decoration: none;
    }

    &--inner {
        display: flex;
        flex-wrap: wrap;
    }

    &--image {
        width: 50%;
        @include mobile() {
            width: 100%;
        }
    }

    &--content {
        width: 50%;
        background-color: $blue;
        padding: 35px 10% 40px 10%;
        position: relative;
        @include mobile() {
            width: 100%;
        }

        &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: -25px;
            width: 0;
            height: 0;
            @include triangle(25.88, 26.6, left, $blue);
            transform: translateY(-50%);
            @include mobile() {
                top: -18px;
                left: 50%;
                @include triangle(25.88, 18.3, top, $blue);
                transform: translateX(-50%);
            }
        }
    }

    &--txt {
        text-align: center;
        max-width: 325px;
        margin: 0 auto;

        &--1 {
            @include yanone70(700, $white);
            @include tablet() {
                @include yanone55(700, $white);
            }
            @include mobile() {
                @include yanone35(700, $white);
            }
        }

        &--2 {
            @include cabin15(400, $white);
            margin-top: 35px;
        }
    }

    &--cta {
        margin-top: 20px;
    }
}