.cta {
    display: flex;
    justify-content: center;

    &--ask-question {
        cursor: pointer;
    }

    .header--aside--cta & {
        justify-content: flex-end;
        @include mobile() {
            transform: scale(0.75);
        }
    }

    .cms--content--actions & {
        margin-left: 20px;
    }

    .colissimo-pickup-point-address & {
        justify-content: flex-start;
    }

    &--link {
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 296px;
        min-height: 53px;
        padding: 0 15px;
        border: none;
        border-radius: 53px;
        transition: all 0.3s;

        .header--aside--cta & {
            justify-content: flex-end;
            width: 255px;
            min-width: 255px;
            height: 53px;
            padding: 0;
            overflow: hidden;
            @include mobile() {
                width: 53px;
                min-width: 53px;
            }

            &--close {
                width: 53px;
                min-width: 53px;

                @include mobile-up() {
                    &:hover {
                        min-width: 255px;
                    }
                }
            }
        }

        .push--intro--actions--cta & {
            justify-content: flex-end;
            min-width: 234px;
            padding: 0 0 0 15px;
        }

        .products-list--product--actions--cta & {
            min-width: 138px;
            min-height: 35px;
            border-radius: 0 35px 35px 0;
            padding: 0;
        }

        .products-list--product--add-to-cart & {
            min-width: 239px;
            border-radius: 0 53px 53px 0;
        }

        .category--heading--cta & {
            border-radius: 0;
        }

        .product--actions & {
            justify-content: flex-start;
            min-width: 250px;
        }

        .product--actions--add-to-cart & {
            cursor: pointer;
        }

        .product--actions--manually-custom &,
        .product--actions--download &,
        .product--actions--print &,
        .product--actions--manually-custom & {
            justify-content: center;
        }

        .product--images--cta & {
            justify-content: space-between;
            min-width: 230px;
            border-radius: 0 53px 53px 0;
            padding: 10px 12px 10px 18px;
        }

        .product--configurator--attributes--action & {
            width: 100%;
            min-height: 35px;
            border-radius: 0 35px 35px 0;
        }

        .colissimo-pickup-point-address & {
            min-width: 236px;
            cursor: pointer;
        }

        .cta--grey & {
            background-color: $grey;
        }

        .cta--white & {
            background-color: $white;
            border: 2px solid $white;

            &:hover {
                .push--intro--item & {
                    background-color: $color1;

                    .cta--txt {
                        color: $white;
                    }
                }
            }

            .push--intro--item & {
                border-color: $color1;
            }

            .push--subsidiesmap--link:hover & {
                background-color: $cta-white-hover;
                border-color: $cta-white-hover;
            }

            .checkout--addresses--action &,
            .checkout--action &,
            .product--actions--print & {
                border-color: $grey;
            }

            .checkout--addresses--action.new & {
                border-color: $color1;
            }
        }

        .cta--green & {
            background-color: $color2;
            border: 2px solid $color2;

            .push--intro--item &,
            .push--intro--actions & {
                border-color: $white;
            }

            .colissimo-pickup-point-address {
                min-width: auto;
            }

            &:hover {
                .push--intro--item & {
                    background-color: $white;
                    border-color: $color2;

                    .cta--txt {
                        color: $color2;
                    }
                }
            }

            .push--links--item--cta:hover & {
                background-color: $color1;
                border-color: $color1;
            }

            .push--blog--item:hover & {
                background-color: $white;
            }
        }

        .cta--green-dark & {
            background-color: $color1;
            border: 2px solid $color1;

            .push--intro--actions & {
                border-color: $white;
            }
        }
    }

    &--txt {
        margin: 0;
        transition: all 0.3s;

        .cta--link--close & {
            display: none;
            opacity: 0;
        }

        @include mobile-up() {
            .cta--link--close:hover & {
                display: block;
                opacity: 1;
            }
        }

        .header--aside--cta & {
            @include mobile {
                display: none;
                opacity: 0;
            }
        }

        .products-list--product--add-to-cart & {
            order: 2;
        }

        .product--actions &,
        .checkout--action & {
            order: 2
        }

        .product--actions--back & {
            text-transform: uppercase;
        }

        .cta--grey & {
            @include cabin20(400, $color1);

            .product--images--cta & {
                @include yanone30(700, $color1);
                text-transform: uppercase;
            }
        }

        .cta--white & {
            @include cabin20(400, $blue);

            .push--intro--item & {
                color: $color1;
            }

            .push--subsidiesmap--link:hover & {
                color: $white;
            }

            .checkout--addresses--action &,
            .checkout--action &,
            .product--actions--print &,
            .product--actions--ask-a-quote &{
                color: $color1;
            }
        }

        .cta--green &,
        .cta--green-dark & {
            @include cabin20(400, $white);

            .colissimo-pickup-point-address & {
                @include cabin15(400, $white);
            }
        }

        .cta--green & {
            .products-list--product--actions--cta &,
            .category--heading--cta &,
            .product--configurator--attributes--action & {
                @include yanone20(700, $white);
                text-transform: uppercase;
            }

            .push--blog--item:hover & {
                color: $color2;
            }

            .push--intro--item--cta & {
                @include cabin15(400, $white);
            }
        }
    }

    &--picto {
        transition: all 0.3s;

        .header--aside--cta &,
        .push--intro--actions--cta & {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 53px;
            height: 53px;
            border-radius: 50%;

        }

        .products-list--product--add-to-cart & {
            margin-right: 10px;
            order: 1;
        }

        .product--actions &,
        .checkout--action & {
            margin-right: 10px;
            order: 1;
        }

        .product--actions--back & {
            height: 19px;
        }

        .cta--green & {
            background-color: $color2;
        }

        .cta--green-dark & {
            background-color: $color1;
        }

        .product--actions--print & {
            svg {
                transform: scale(1.3);

                .cls-1 {
                    fill: $white;
                }

                .cls-2 {
                    fill: $color1;
                }
            }
        }

        svg {
            .cta--white & {
                color: $color1;
            }
        }
    }
}